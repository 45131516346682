import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSearchedProducts } from '../api/products';
import Loading from '../components/Loading';
import ProductCard from '../components/ProductCard';
import ProductSortBy from '../components/ProductSortBy';
import { setProducts } from '../state/productsSlice';
import { SMALL_DEVICES_BREAKPOINT } from '../variables';
import { MainContainer } from '../styles';

const SearchPage = () => {
  const XSMALL_DEVICES = useMediaQuery(`(max-width:${SMALL_DEVICES_BREAKPOINT})`);
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state);
  const products = state.products.products;
  const [cookies] = useCookies(['userToken']);
  const showPriceToUser = !!cookies.userToken;
  const [noProductsFound, setNoProductsFound] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchInput = searchParams.get('query');
  const sortBy = useSelector((state) => state.products.sortBy);

  useEffect(() => {
    setIsLoading(true);

    getSearchedProducts(searchInput, sortBy)
      .then((products) => {
        dispatch(setProducts(products));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      })
      .then(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchInput, sortBy]);

  useEffect(() => {
    const newNoProductsFound = products.length === 0 && !isLoading;

    setNoProductsFound(newNoProductsFound);
  }, [isLoading, products]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <Container>
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: XSMALL_DEVICES && 'column',
              marginBottom: { xs: '20px', md: '30px' },
            }}
          >
            <Typography
              variant='h5'
              sx={{
                flex: 1,
                marginBottom: { xs: '20px', sm: '0px' },
              }}
            >
              Resultados de pesquisa para "{searchInput}"
            </Typography>

            {!noProductsFound && <ProductSortBy XSMALL_DEVICES={XSMALL_DEVICES} />}
          </Box>

          {!noProductsFound ? (
            <Grid container spacing={2}>
              {products.map((product) => (
                <Grid key={`searched-result-${product._id}`} xs={6} sm={4} md={3} lg={2.4}>
                  <ProductCard product={product} showPrice={showPriceToUser} showStockAvailability />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box sx={{ height: '25vh' }}>
              <Typography variant='h6'>{`Não foram encontrados resultados para ${
                searchInput || 'a sua pesquisa'
              }. Verifique a ortografia ou utilize uma pesquisa diferente.`}</Typography>
            </Box>
          )}
        </Box>
      </Container>
    </MainContainer>
  );
};

export default SearchPage;
