import { Box, Typography } from '@mui/material';
import React from 'react';
import ProductCarousel from '../ProductCarousel';

const ProductRelated = ({ related }) => {
  return (
    <Box marginTop={{ xs: 6, md: 8 }}>
      <Typography
        variant='h5'
        marginBottom={2}
      >
        Produtos Relacionados
      </Typography>
      <ProductCarousel products={related} />
    </Box>
  );
};

export default ProductRelated;
