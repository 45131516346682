import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { recoverPassword } from '../../api/login';
import { StyledFormInputLabel, StyledFormTextfield, StyledTextButton } from '../../styles';
import { PASS_RECOVERY_TEXT, PASS_RECOVERY_TITLE } from '../../variables';

const PasswordRecovery = () => {
  const [loading, setLoading] = useState(false);

  const [confirmation, setConfirmation] = useState(false);

  const [recoveryEmail, setRecoveryEmail] = useState('');

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    if (!recoveryEmail) return;

    recoverPassword(recoveryEmail)
      .then(() => {})
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status >= 400) {
            console.log('message', msg);
          }
        }
      )
      .then(() => {
        setLoading(false);

        setConfirmation(true);
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '80vw', sm: '30vw' },
          marginY: { xs: '20px', sm: '60px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: 1,
          }}
        >
          <StyledFormInputLabel sx={{ textAlign: 'center', marginBottom: 1 }}>{PASS_RECOVERY_TITLE}</StyledFormInputLabel>
          <Typography sx={{ textAlign: 'center', fontSize: '.9em' }}>{PASS_RECOVERY_TEXT}</Typography>
        </Box>

        <StyledFormTextfield
          fullWidth
          label='Email'
          type='email'
          required
          value={recoveryEmail}
          onChange={(e) => setRecoveryEmail(e.target.value)}
        />

        {loading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton
            type='submit'
            variant='contained'
            title='Submeter'
          >
            Submeter
          </StyledTextButton>
        )}
      </Box>

      <Snackbar
        autoHideDuration={4000}
        message='Uma mensagem foi enviada para o email indicado.'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={confirmation}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setConfirmation(false);
        }}
        ContentProps={{ sx: { backgroundColor: '#4FD290' } }}
      />
    </>
  );
};

export default PasswordRecovery;
