import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { imageOnError } from '../../helpers';
import ReactElasticCarousel from 'react-elastic-carousel';
import ImageZoomModal from '../ImageZoomModal';

const ProductImages = ({ images }) => {
  const [shownImage, setShownImage] = useState(0);
  const [firstImageExists, setFirstImageExists] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [zoomImagePath, setZoomImagePath] = useState('');

  const breakpoints = [
    { width: 0, itemsToShow: 3 },
    { width: 600, itemsToShow: 3 },
    { width: 900, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <>
      <Box
        sx={{
          maxWidth: '400px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        gap={firstImageExists && images.length > 1 && 3}
      >
        <Button
          title='Ver imagem'
          onClick={() => {
            setZoomImagePath(images[shownImage]);
            setModalIsOpen(true);
          }}
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'zoom-in',
            },
          }}
        >
          <Box
            component='img'
            src={images[shownImage]}
            alt='Imagem destacada'
            onError={(e) => {
              imageOnError(e);
              setFirstImageExists(false);
            }}
            sx={{
              aspectRatio: '1',
              objectFit: 'contain',
              maxWidth: '100%',
            }}
          />
        </Button>

        {firstImageExists && images.length > 1 && (
          <ReactElasticCarousel
            breakPoints={breakpoints}
            itemPadding={[5]}
            showArrows={images.length > 3 ? true : false}
            pagination={false}
          >
            {images.map((productImage, index) => (
              <Box
                key={`thumbnail-image-${index}`}
                component='img'
                src={productImage}
                onClick={() => setShownImage(index)}
                hidden
                onLoad={(e) => {
                  e.target.hidden = false;
                }}
                onError={(e) => {
                  e.target.remove();
                }}
                className={index === shownImage && 'productThumbnailActive'}
                sx={{
                  maxWidth: '100%',
                  aspectRatio: '1',
                  objectFit: 'contain',
                  border: `2px solid ${index === shownImage ? '#ff0000' : 'transparent'}`,
                  padding: '5px',
                  transition: 'border-color 200ms ease',
                  '&:hover': {
                    border: '2px solid #ff0000',
                    cursor: 'pointer',
                  },
                }}
                alt={`Imagem thumbnail ${index}`}
              />
            ))}
          </ReactElasticCarousel>
        )}
      </Box>
      <ImageZoomModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        imagePath={zoomImagePath}
      />
    </>
  );
};

export default ProductImages;
