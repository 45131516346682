import { Box, Typography } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTextButton } from '../../../styles';
import SummaryTable from './SummaryTable/SummaryTable';

const CartSummary = ({ setActiveStep, totalPrice, customerId }) => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{
          marginTop: { xs: '40px', md: '60px' },
          marginBottom: { xs: '30px', md: '40px' },
          textAlign: 'center',
        }}
      >
        Resumo do Carrinho
      </Typography>

      <SummaryTable customerId={customerId} />

      {totalPrice !== 0 && (
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 5,
            textAlign: { xs: 'left', md: 'right' },
          }}
        >
          <Typography
            sx={{
              fontSize: '0.8rem',
            }}
          >
            Portes gratuitos em encomendas acima de 300€
          </Typography>
          <Typography
            variant='h6'
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 1,
              fontSize: '1rem',
              marginTop: 1.5,
            }}
          >
            Total da encomenda:
            <Box sx={{ fontSize: '1.4em', fontWeight: 700 }}>
              {formatCurrency(totalPrice)}
            </Box>
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondaryColorButton'
          href='/'
          sx={{ marginLeft: 'auto' }}
        >
          Voltar à Loja
        </StyledTextButton>

        <StyledTextButton
          variant='contained'
          onClick={() => setActiveStep(1)}
          sx={{ marginLeft: '10px' }}
        >
          Continuar
        </StyledTextButton>
      </Box>
    </>
  );
};

export default CartSummary;
