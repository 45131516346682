import { AppBar, Box, Container, useMediaQuery } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/companyLogo.png';
import { SMALL_DEVICES_BREAKPOINT, XLARGE_DEVICES_BREAKPOINT } from '../../variables';
import AccountLinks from './AccountLinks/AccountLinks';
import ClientSelector from './ClientSelector';
import NavBar from './NavBar/NavBar';
import SearchBar from './SearchBar';

const Header = () => {
  const XSMALL_DEVICES = useMediaQuery(`(max-width:${SMALL_DEVICES_BREAKPOINT})`);

  const XLARGE_DEVICES = useMediaQuery(`(min-width:${XLARGE_DEVICES_BREAKPOINT})`);

  const role = useSelector((state) => state.user.role);

  return (
    <AppBar position='sticky' color='inherit' elevation={0}>
      {role && role !== 'customer' && <ClientSelector userRole={role} XLARGE_DEVICES={XLARGE_DEVICES} />}
      <Container>
        <Grid2 container justifyContent='space-between' alignItems='center' sx={{ paddingY: '15px' }}>
          <Grid2>
            <Box component={Link} to='/' sx={{ display: 'flex', width: { xs: '150px', md: '234px' } }}>
              <img src={Logo} alt='Imporaudio' width='100%' />
            </Box>
          </Grid2>
          {XSMALL_DEVICES ? (
            <>
              <AccountLinks />
              <SearchBar />
            </>
          ) : (
            <>
              <SearchBar />
              <AccountLinks />
            </>
          )}
        </Grid2>
      </Container>
      <NavBar />
    </AppBar>
  );
};

export default Header;
