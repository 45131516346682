import { Box, Button, OutlinedInput, styled } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addUserSelection } from '../../../state/productSlice';

const QuantitySelectorInput = styled(OutlinedInput)(() => ({
  fontSize: '0.85rem',
  width: '40px',
  height: '28px',
  '.MuiInputBase-input': {
    textAlign: ' center',
    padding: 0,
  },
}));

const QuantitySelector = ({ product, quantity, selectForCart }) => {
  const dispatch = useDispatch();

  const { id } = product;

  function decrement(id) {
    if (quantity.value < 1) {
      return;
    }

    const newQuantity = quantity.value - 1;

    quantity.setQuantity(newQuantity);

    const productQuantity = newQuantity;

    const newProduct = { ...product, quantity: productQuantity };

    selectForCart(newProduct);
  }

  function increment(id) {
    const newQuantity = quantity.value + 1;

    quantity.setQuantity(newQuantity);

    const productQuantity = newQuantity;

    const newProduct = { ...product, quantity: productQuantity };

    selectForCart(newProduct);
  }

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3) return;

    const newQuantityNumbered = Number(e);

    //  IF new quantity typed is greater than available stock » return
    // if (newQuantityNumbered > stock) return;

    quantity.setQuantity(newQuantityNumbered);

    //  IF number of caracteres is equal 0 » return
    if (e.length === 0) return;
    selectForCart({ ...product, quantity: Math.round(newQuantityNumbered) });
  };

  useEffect(() => {
    const userSelection = {
      ...product,
      quantity: quantity.value,
    };

    dispatch(addUserSelection(userSelection));
  }, [dispatch, quantity, product]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <Button
        sx={{
          minWidth: '24px',
          minHeight: '28px',
          lineHeight: 1,
          padding: '8px',
        }}
        disabled={quantity?.value <= 1}
        onClick={() => decrement(id)}
      >
        -
      </Button>

      <QuantitySelectorInput
        id='quantity-selector'
        required
        // disabled={!!!stock}
        value={quantity?.value}
        onChange={(e) =>
          e.target.value !== quantity?.value
            ? openFieldChange(e.target.value)
            : null
        }
      />

      <Button
        sx={{
          minWidth: '24px',
          minHeight: '28px',
          lineHeight: 1,
          padding: '8px',
        }}
        // disabled={quantity?.value >= stock}
        onClick={() => increment(id)}
      >
        +
      </Button>
    </Box>
  );
};

export default QuantitySelector;
