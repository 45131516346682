import { FiUser } from 'react-icons/fi';

const API_URL = `${process.env.REACT_APP_API_URL}/store`;
const IMAGES_PATH = '/public/images/';
const NOT_FOUND_ROUTE = 'pagina-nao-encontrada';

// info
const COMPANY_INFO = {
  name: 'Imporaudio',
  address: {
    street: 'Rua do Padrão 76-140',
    zipCode: '4455-267 Perafita',
    city: 'Matosinhos',
  },
  phone: '+351 229 966 738',
  fax: '+351 229 966 741',
  email: 'imporaudio@imporaudio.com',
  social: {
    linkedin: 'https://www.linkedin.com/company/imporaudio/',
    facebook: 'https://www.facebook.com/imporaudioportugal88/',
    instagram: 'https://www.instagram.com/imporaudio/',
  },
};

// login
const LOGIN_FORM_TITLE = 'Clientes Profissionais';

const LOGIN_PASS_RECOVERY_TEXT = 'Esqueceu-se da palavra-passe?';

const LOGIN_REGISTER_TEXT = 'Ainda não tem conta de cliente?';

const PASS_RECOVERY_TITLE = 'Recuperar Palavra-Passe';

const PASS_RECOVERY_TEXT =
  'Após recuperar a palavra-passe irá receber uma nova no seu email.';

const USER_REGISTER_TITLE = 'Registo de Novo Utilizador';

const REGISTER_SUCCESS_TEXT =
  'O seu registo foi efetuado com sucesso. Receberá um email com a aprovação da conta.';

const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo'];
// const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo', 'Downloads'];

// breakpoints
const SMALL_DEVICES_BREAKPOINT = '600px';

const MEDIUM_DEVICES_BREAKPOINT = '900px';

const LARGE_DEVICES_BREAKPOINT = '1200px';

const XLARGE_DEVICES_BREAKPOINT = '1536px';

// color
const BG_PRIMARY_COLOR = '#FFF';

const BG_SECONDARY_COLOR = '#F7F7F7';

const PRIMARY_COLOR = '#FFF';

const SECONDARY_COLOR = '#818181';

const ACCENT_COLOR = '#FE0000';

// header
const ACCOUNT_LINKS = [
  {
    title: 'User',
    url: '',
    icon: <FiUser size={24} />,
    submenu: [
      { title: 'Os meus dados', url: '/conta/info' },
      {
        title: 'Encomendas',
        url: '/conta/encomendas',
      },
      {
        title: 'Conta Corrente',
        url: '/conta/conta-corrente',
      },
      { title: 'Terminar sessão', url: '/' },
    ],
  },
];

//  INSTITUTIONAL PAGES SLUGS
const PAGES_SLUGS = {
  aboutUs: '/page/quem-somos',
  termsAndConditions: '/page/termos-e-condicoes',
  privacyPolicy: '/page/politica-de-privacidade',
  contacts: '/page/contactos',
};

// navbar
const NAVBAR_LINKS = [
  { title: 'Produtos', url: '', menuMobile: false },
  { title: 'Campanhas', url: '/campanhas', menuMobile: true },
  { title: 'Novidades', url: '/novidades', menuMobile: true },
  { title: 'Marcas', url: '/marcas', menuMobile: true },
  { title: 'Quem somos', url: PAGES_SLUGS.aboutUs, menuMobile: true },
  { title: 'Contactos', url: PAGES_SLUGS.contacts, menuMobile: true },
];

export {
  API_URL,
  NOT_FOUND_ROUTE,
  COMPANY_INFO,
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
  PASS_RECOVERY_TITLE,
  PASS_RECOVERY_TEXT,
  USER_REGISTER_TITLE,
  REGISTER_SUCCESS_TEXT,
  SMALL_DEVICES_BREAKPOINT,
  MEDIUM_DEVICES_BREAKPOINT,
  LARGE_DEVICES_BREAKPOINT,
  XLARGE_DEVICES_BREAKPOINT,
  BG_PRIMARY_COLOR,
  BG_SECONDARY_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  ACCENT_COLOR,
  ACCOUNT_LINKS,
  NAVBAR_LINKS,
  IMAGES_PATH,
  PAGES_SLUGS,
  PRODUCT_INFO_TABS,
};
