import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from 'react';
import ProductCard from '../ProductCard';

const GridView = ({ products }) => {
  const [showStockAvailability] = useState(true);
  return (
    <Grid
      container
      sx={{
        padding: 0,
      }}
    >
      {products.map((product, index) => (
        <Grid
          key={`${product.reference}-${index}`}
          xs={6}
          sm={4}
          lg={3}
        >
          <ProductCard
            product={{ ...product }}
            showStockAvailability={showStockAvailability}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GridView;
