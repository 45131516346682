import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { updateUser } from '../../../api/user';
import { isObjectEmpty } from '../../../constants/utils';
import { setPersonalInfo } from '../../../state/userSlice';
import { StyledTextButton } from '../../../styles';
import CompanyInfo from './CompanyInfo';
import PersonalInfo from './PersonalInfo';
import SellerInfo from './SellerInfo';

const AccountInfo = () => {
  const [SMALL_DEVICES] = useOutletContext();

  const { selectedClient, personalInfo, companyInfo, sellerInfo } = useSelector(
    (state) => state.user
  );
  const isSeller = !isObjectEmpty(selectedClient);

  const { name, phone, email } = isSeller
    ? selectedClient.personalInfo
    : personalInfo;

  const [userData, setUserData] = useState({ name, phone, email });

  const [isReadOnly, setIsReadOnly] = useState(true);

  const [buttonText, setButtonText] = useState('Editar Informações');

  const dispatch = useDispatch();

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));
  }

  function handleEdit() {
    const buttonText = isReadOnly
      ? 'Guardar Informações'
      : 'Editar Informações';

    setButtonText(buttonText);

    setIsReadOnly((prevState) => !prevState);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (!userData) return;

    updateUser(userData).then(({ user: { personalInfo } }) => {
      dispatch(setPersonalInfo(personalInfo));
    });
  }

  if (!personalInfo || !companyInfo || !sellerInfo) return null;

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        gutterBottom
        sx={{ fontWeight: 700, textTransform: 'uppercase' }}
      >
        Informações da Empresa
      </Typography>

      <Typography gutterBottom>
        Consulte os dados da sua empresa e edite a sua informação de contacto.
      </Typography>

      <CompanyInfo data={isSeller ? selectedClient.companyInfo : companyInfo} />

      <Box
        sx={{
          marginY: '30px',
          display: 'flex',
          flexDirection: SMALL_DEVICES && 'column',
          gap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            gutterBottom
            sx={{
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            Informações de contacto
          </Typography>

          <PersonalInfo
            handleChange={handleChange}
            isReadOnly={isReadOnly}
            userData={userData}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography
            gutterBottom
            sx={{
              fontWeight: 700,
              textTransform: 'uppercase',
            }}
          >
            Informações do vendedor
          </Typography>

          <SellerInfo />
        </Box>
      </Box>

      <StyledTextButton
        variant='contained'
        type={!isReadOnly ? 'button' : 'submit'}
        onClick={handleEdit}
        sx={{ marginRight: 'auto' }}
      >
        {buttonText}
      </StyledTextButton>
    </Box>
  );
};

export default AccountInfo;
