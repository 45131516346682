import { formatTextToTitleCase } from '../constants/utils';
import defaultImage from '../assets/default_image.png';
import { capitalize, kebabCase } from 'lodash';

const generatePath = (categoriesArray) => {
  return categoriesArray.reduce((acc, item, index, originalArray) => {
    const slug = originalArray
      .slice(0, index + 1)
      .map((slugItem) => kebabCase(slugItem))
      .join('/');
    acc.push({
      name: capitalize(item),
      slug: `/${slug}`,
    });
    return acc;
  }, []);
};

function mapProduct(product) {
  return {
    ...product,
    id: product._id,
    path: generatePath([
      product?.collection || 'collection',
      product?.category || 'category',
      product?.productType || 'productType',
    ]),
    title: product.title,
    brand: product.brand,
    description: product.description,
    reference: product.reference,
    images: product.images.length ? product.images : [defaultImage],
    price: product.price,
    pvpPrice: product.pvpPrice,
    onSale: product.onSale,
    salePrice: product.salePrice,
    stock: product.stock,
    video: product.video,
    datasheet: product.datasheet,
    params: product.params,
    related: product.related,
    date: product.erp_update_date,
    deliveryDate: Boolean(product.deliveryDate) ? new Date(product.deliveryDate).toLocaleDateString('pt-PT') : '',
    deliveryQuantity: product.deliveryQuantity,
  };
}

function mapCategory(category) {
  return {
    ...category,
    id: category._id,
    text: formatTextToTitleCase(category.title),
    // date: category.erp_update_date,
    url: category.url,
    subcategories: category.subcategories.map((subcategory) => ({
      ...subcategory,
      id: subcategory._id,
      text: formatTextToTitleCase(subcategory.title.toString()),
      // date: subcategory.erp_update_date,
      url: `/${category.url}/${subcategory.url}`,
      productTypes: subcategory.productTypes
        .map((productType) => ({
          ...productType,
          id: productType._id,
          text: formatTextToTitleCase(productType.title.toString()),
          // date: productType.erp_update_date,
          url: `/${category.url}/${subcategory.url}/${productType.url}`,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => {
    const { title, values } = value;
    return {
      id: key,
      title: title,
      options: values,
    };
  });
}

export { mapProduct, mapCategory, mapFilters, generatePath };
