import {
  Box,
  Icon,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiCheck, FiEdit2, FiGift, FiPercent } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '../../../../constants/utils';
import { StyledCartTableCell, StyledTextButton } from '../../../../styles';
import { removeProductFromCart, updateCartProducts } from '../../../../api/checkout';
import DiscountSelectorModal from '../DiscountSelectorModal';
import SummaryProductInfo from './SummaryProductInfo';
import SummaryQuantity from './SummaryQuantity';
import { setCart, setCartTotal, setDiscount } from '../../../../state/cartSlice';
import ProductOfferModal from '../ProductOfferModal';
import { useNavigate } from 'react-router-dom';

const SummaryTable = ({ customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isApplyDiscountOpen, setIsApplyDiscountOpen] = useState(false);
  const [productOfferModal, setProductOfferModal] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user);
  const [iconButton, setIconButton] = useState(null);
  const showDiscountOptions = user.role === 'seller';
  const [productToUpdate, setProductToUpdate] = useState();

  const updateTotalWithDiscount = (product, discount, priceWithDiscount) => {
    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(
      [
        {
          ...product,
          customer_price: priceWithDiscount,
          discount: discount,
        },
      ],
      customerId
    ).then(({ cart_items, total, total_discount }) => {
      dispatch(setCart(cart_items));
      dispatch(setDiscount(total_discount));
      dispatch(setCartTotal(total));
    });
  };

  const addProductOffer = (offerProduct) => {
    let newCart = cart;
    const mainProduct = newCart.find((product) =>
      offerProduct.reference.includes(product.reference)
    );
    const mainProductQuantityIsZero = mainProduct.quantity - offerProduct.quantity === 0;
    const offerProductAlreadyExists = newCart.filter(
      (product) => product.reference === offerProduct.reference
    );

    //  remove 'main product' from cart
    if (mainProductQuantityIsZero) {
      newCart = newCart.filter((product) => product.reference !== mainProduct.reference);

      removeProductFromCart(mainProduct.reference, customerId)
        .then(({ cart_items, total: totalPrice, total_discount }) => {
          dispatch(setCart(cart_items));
          dispatch(setDiscount(total_discount));
          dispatch(setCartTotal(totalPrice));
        })
        .catch(({ response: { status } }) => {
          if (status === 401) navigate('/login');
        });
    }

    if (!!offerProductAlreadyExists.length) {
      newCart = newCart.map((product) =>
        //  subtract 'offer product' quantity to his 'main product'
        product.reference === mainProduct.reference && !mainProductQuantityIsZero
          ? {
              ...product,
              quantity: product.quantity - offerProduct.quantity,
            }
          : //  sum quantity to existing 'offer product'
          product.reference === offerProduct.reference
          ? {
              ...product,
              quantity: product.quantity + offerProduct.quantity,
            }
          : product
      );
    } else {
      newCart = [
        ...newCart.map((stateProduct) =>
          //  subtract 'offer product' quantity to his 'main product'
          stateProduct.reference === mainProduct.reference && !mainProductQuantityIsZero
            ? {
                ...stateProduct,
                quantity: stateProduct.quantity - offerProduct.quantity,
              }
            : stateProduct
        ),
        //  add new 'offer product'
        offerProduct,
      ];
    }

    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(newCart, customerId)
      .then(({ cart_items, total, total_discount }) => {
        dispatch(setCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginY: '10px' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledCartTableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                Designação
              </StyledCartTableCell>
              <StyledCartTableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>
                Quantidade
              </StyledCartTableCell>
              <StyledCartTableCell sx={{ minWidth: { xs: '150px', md: 'initial' } }}>
                Preço
              </StyledCartTableCell>
              {showDiscountOptions && (
                <StyledCartTableCell sx={{ minWidth: { xs: '240px', md: 'initial' } }}>
                  Ações
                </StyledCartTableCell>
              )}
              <StyledCartTableCell sx={{ minWidth: { xs: '100px', md: 'initial' } }}>
                Subtotal
              </StyledCartTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cart) &&
              cart.map((product, index) => {
                const {
                  _id: id,
                  title,
                  brand,
                  reference,
                  price,
                  salePrice,
                  onSale,
                  quantity,
                  images,
                  discount = 0,
                } = product;
                const pricePerUnit = onSale ? salePrice : price;
                const hasDiscount = discount > 0;
                const isProductsOffer = reference?.includes('offer');

                const subtotalWithDiscount = parseFloat(
                  Math.fround(
                    pricePerUnit * quantity - (pricePerUnit * quantity * discount) / 100
                  ).toFixed(2)
                );

                return (
                  <TableRow
                    key={`${reference}-${id}-${index}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledCartTableCell>
                      {/* designation */}
                      <SummaryProductInfo
                        id={id}
                        images={images}
                        title={title}
                        reference={reference}
                        brand={brand}
                        offerProduct={isProductsOffer && user.role === 'customer'}
                      />
                    </StyledCartTableCell>

                    <StyledCartTableCell>
                      {/* quantity */}
                      <SummaryQuantity
                        quantity={quantity}
                        id={id}
                        reference={reference}
                        cart={cart}
                        customerId={customerId}
                        disableActions={isProductsOffer && user.role === 'customer'}
                      />
                    </StyledCartTableCell>

                    <StyledCartTableCell>
                      {/* your price */}
                      {onSale ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ textDecoration: 'line-through' }}>
                            {formatCurrency(price)}
                          </Typography>
                          <Typography sx={{ fontWeight: 600, color: 'danger.main' }}>
                            {formatCurrency(pricePerUnit)}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography>{formatCurrency(pricePerUnit)}</Typography>
                      )}
                    </StyledCartTableCell>

                    {/* seller discount */}
                    {showDiscountOptions && (
                      <StyledCartTableCell>
                        {isProductsOffer ? (
                          <Typography
                            sx={{
                              color: 'danger.main',
                              fontWeight: 600,
                            }}
                          >
                            OFERTA
                          </Typography>
                        ) : (
                          <Box
                            sx={{
                              display: 'grid',
                              justifyItems: 'center',
                              gap: 0.5,
                            }}
                          >
                            <StyledTextButton
                              title={hasDiscount ? 'Editar desconto' : 'Aplicar desconto'}
                              sx={{
                                gap: 1,
                                fontSize: '0.85rem',
                                color: hasDiscount ? 'danger.main' : 'inherit',
                              }}
                              onMouseEnter={() => {
                                return hasDiscount && setIconButton(index);
                              }}
                              onMouseLeave={() => {
                                return hasDiscount && setIconButton(null);
                              }}
                              onClick={() => {
                                setProductToUpdate(product);
                                setIsApplyDiscountOpen(true);
                              }}
                            >
                              <Icon
                                sx={{
                                  backgroundColor: '#D9D9D9',
                                  borderRadius: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                {hasDiscount ? (
                                  iconButton === index ? (
                                    <FiEdit2 size={12} />
                                  ) : (
                                    <FiCheck size={12} />
                                  )
                                ) : (
                                  <FiPercent size={12} />
                                )}
                              </Icon>
                              {hasDiscount ? `Desconto ${discount}%` : `Desconto Vendedor`}
                            </StyledTextButton>
                            {!hasDiscount && (
                              <StyledTextButton
                                title='Oferecer Produto'
                                sx={{
                                  gap: 1,
                                  fontSize: '0.85rem',
                                  color: 'inherit',
                                }}
                                onClick={() => {
                                  setProductToUpdate(product);
                                  setProductOfferModal(true);
                                }}
                              >
                                <Icon
                                  sx={{
                                    backgroundColor: '#D9D9D9',
                                    borderRadius: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <FiGift size={12} />
                                </Icon>
                                Oferecer Produto
                              </StyledTextButton>
                            )}
                          </Box>
                        )}
                      </StyledCartTableCell>
                    )}

                    <StyledCartTableCell>
                      {/* subtotal */}
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: hasDiscount && 'line-through',
                            fontWeight: !hasDiscount && 600,
                          }}
                        >
                          {formatCurrency(pricePerUnit * quantity)}
                        </Typography>

                        {hasDiscount && (
                          <Typography
                            sx={{
                              color: hasDiscount && 'danger.main',
                              fontWeight: 600,
                            }}
                          >
                            {formatCurrency(subtotalWithDiscount)}
                          </Typography>
                        )}
                      </Box>
                    </StyledCartTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isApplyDiscountOpen && (
        <DiscountSelectorModal
          isApplyDiscountOpen={isApplyDiscountOpen}
          setIsApplyDiscountOpen={setIsApplyDiscountOpen}
          updateTotalWithDiscount={updateTotalWithDiscount}
          product={productToUpdate}
        />
      )}
      {productOfferModal && (
        <ProductOfferModal
          productOfferModal={productOfferModal}
          setProductOfferModal={setProductOfferModal}
          addProductOffer={addProductOffer}
          product={productToUpdate}
        />
      )}
    </>
  );
};

export default SummaryTable;
