import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatCurrency } from '../../../constants/utils';
import { StyledCartTableCell, StyledTextButton } from '../../../styles';
import { QuantitySelectorInput } from './SummaryTable/SummaryQuantity';

const DiscountSelectorModal = ({
  isApplyDiscountOpen,
  setIsApplyDiscountOpen,
  updateTotalWithDiscount,
  product,
}) => {
  const [discount, setDiscount] = useState(product.discount ?? 0);
  const [temporaryDiscount, setTemporaryDiscount] = useState(discount);
  const productPrice = product.onSale ? product.salePrice : product.price;
  const priceWithDiscount = parseFloat(
    Math.fround(productPrice - (productPrice * discount) / 100).toFixed(2)
  );

  function decrement() {
    if (discount < 1) return;
    setDiscount(discount - 1);
  }

  function increment() {
    if (discount > 100) return;
    setDiscount(discount + 1);
  }

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3) return;

    const newQuantityNumbered = Number(e);

    setTemporaryDiscount(newQuantityNumbered);

    //  IF number of caracteres is equal 0 » return
    if (e.length === 0) return;

    setDiscount(newQuantityNumbered);
  };

  useEffect(() => {
    setTemporaryDiscount(discount);
  }, [discount]);

  return (
    <Dialog
      open={isApplyDiscountOpen}
      onClose={() => setIsApplyDiscountOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', md: '500px' },
          margin: { xs: 2, md: 4 },
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, paddingY: 3 }}>
        Adicionar desconto
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledCartTableCell>Preço</StyledCartTableCell>
                <StyledCartTableCell>Preço c/ desconto</StyledCartTableCell>
                <StyledCartTableCell>Desconto (%)</StyledCartTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledCartTableCell>
                  <Typography sx={{ textDecoration: 'line-through' }}>
                    {formatCurrency(productPrice)}
                  </Typography>
                </StyledCartTableCell>
                <StyledCartTableCell>
                  <Typography sx={{ fontWeight: 600, color: 'danger.main' }}>
                    {formatCurrency(priceWithDiscount)}
                  </Typography>
                </StyledCartTableCell>
                <StyledCartTableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 0.5,
                    }}
                  >
                    <Button
                      sx={{ minWidth: '30px' }}
                      onClick={decrement}
                      disabled={discount === 0}
                    >
                      -
                    </Button>

                    <QuantitySelectorInput
                      id='discountPercentage-selector'
                      required
                      value={temporaryDiscount}
                      onChange={(e) =>
                        e.target.value !== temporaryDiscount
                          ? openFieldChange(e.target.value)
                          : null
                      }
                      onBlur={({ target: { value } }) =>
                        Number(value) === 0 && setTemporaryDiscount(discount)
                      }
                    />

                    <Button
                      sx={{ minWidth: '30px' }}
                      onClick={increment}
                      disabled={discount === 100}
                    >
                      +
                    </Button>
                  </Box>
                </StyledCartTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions
          sx={{
            padding: 0,
            marginTop: 3,
          }}
        >
          <StyledTextButton
            title='title'
            variant='contained'
            color='secondaryColorButton'
            onClick={() => setIsApplyDiscountOpen(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            title='Aplicar desconto'
            variant='contained'
            onClick={() => {
              setIsApplyDiscountOpen(false);
              updateTotalWithDiscount(product, discount, priceWithDiscount);
            }}
          >
            Aplicar desconto
          </StyledTextButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DiscountSelectorModal;
