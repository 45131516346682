import {
  // IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
// import { BiPrinter } from 'react-icons/bi';
import { formatCurrency } from '../../../constants/utils';
import { StyledTableCell } from '../../../styles';
// import generatePDF from '../../../helpers/generatePDF';

const ReceiptsTable = ({ receipts }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ marginY: '20px' }}
    >
      <Table
        size='small'
        id='receipts-table'
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Número</StyledTableCell>
            <StyledTableCell>Tipo</StyledTableCell>
            <StyledTableCell>Data</StyledTableCell>
            <StyledTableCell>Data de vencimento</StyledTableCell>
            <StyledTableCell>Total</StyledTableCell>
            <StyledTableCell>Estado</StyledTableCell>
            <StyledTableCell>Pendente</StyledTableCell>
            {/* <StyledTableCell> </StyledTableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {receipts.map(
            ({
              reference,
              docType,
              date,
              dueDate,
              status,
              value: total,
              balance: pending,
            }) => {
              const dateFormatted = new Date(date).toLocaleDateString('pt-PT');
              const dueDateFormatted = new Date(dueDate).toLocaleDateString(
                'pt-PT'
              );
              return (
                <TableRow
                  key={`${reference}-${date}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StyledTableCell>{reference}</StyledTableCell>
                  <StyledTableCell>{docType}</StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dateFormatted}
                  </StyledTableCell>
                  <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dueDateFormatted}
                  </StyledTableCell>
                  <StyledTableCell>{formatCurrency(total)}</StyledTableCell>
                  <StyledTableCell>{status}</StyledTableCell>
                  <StyledTableCell>{formatCurrency(pending)}</StyledTableCell>

                  {/* <StyledTableCell>
                  <IconButton
                    size='small'
                    title='Exportar em PDF'
                    onClick={() => generatePDF(reference, formatCurrency(total), date, items)}
                  >
                    <BiPrinter />
                  </IconButton>
                </StyledTableCell> */}
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReceiptsTable;
