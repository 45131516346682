import { Grid, Typography } from '@mui/material';
import React from 'react';
import { COMPANY_INFO } from '../../variables';

const ContactUs = () => {
  const {
    address: { street, zipCode, city },
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h5'
        gutterBottom
        sx={{ fontSize: '1.1em' }}
      >
        Contacte-nos
      </Typography>

      <Typography gutterBottom>
        {street} <br /> {zipCode}
        <br />
        {city}
      </Typography>

      <Typography
        component={'a'}
        href={`tel:${phone}`}
        sx={{ display: 'block', color: 'black', textDecoration: 'none' }}
      >
        <strong>Telf.</strong> {phone}
      </Typography>

      <Typography variant='caption'>{`(Chamada para a rede fixa nacional)`}</Typography>

      <Typography
        gutterBottom
        sx={{ marginTop: '16px' }}
      >
        <strong>Fax.</strong> {fax}
      </Typography>

      <Typography
        gutterBottom
        component={'a'}
        href={`mailto:${email}`}
        sx={{ display: 'block', color: 'black', textDecoration: 'none' }}
      >
        <strong>Email.</strong> {email}
      </Typography>
    </Grid>
  );
};

export default ContactUs;
