import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { StyledTextButton } from '../../styles';
import { useDispatch } from 'react-redux';
import { setCart, setCartTotal } from '../../state/cartSlice';
const OrderConfirmation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //  reset total cart
    dispatch(setCartTotal(0));
    //  reset cart items
    dispatch(setCart([]));
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        marginTop: { xs: '40px', md: 0 },
      }}
    >
      <IconContext.Provider value={{ style: { color: '#4FD290' } }}>
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography
        gutterBottom
        sx={{ fontWeight: 700, marginTop: { xs: '20px', md: '40px' } }}
      >
        A sua encomenda foi finalizada com sucesso.
      </Typography>

      <Typography>De seguida, irá receber um email com todos os detalhes da sua encomenda.</Typography>

      <Box
        sx={{
          marginY: { xs: '20px', md: '40px' },
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <StyledTextButton
          title='Voltar à homepage'
          variant='contained'
          component={Link}
          to='/'
          sx={{ textAlign: 'center' }}
        >
          Voltar à homepage
        </StyledTextButton>

        <StyledTextButton
          title='Ver encomendas'
          variant='contained'
          component={Link}
          to='/conta/encomendas'
          sx={{ textAlign: 'center' }}
        >
          Ver&nbsp;<strong>Encomendas</strong>
        </StyledTextButton>
      </Box>
    </Box>
  );
};

export default OrderConfirmation;
