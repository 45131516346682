import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiSettings, FiShoppingCart, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatTextToTitleCase } from '../../../constants/utils';
import { setPvprVisibility } from '../../../state/userSlice';
import { HeaderAccountButton, HeaderAccountButtonText, StyledTextButton } from '../../../styles';
import { ACCOUNT_LINKS } from '../../../variables';
import UserMenu from './UserMenu';

const AccountLinks = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart?.cart);
  const user = useSelector((state) => state.user);
  const { pvprIsVisible, role, personalInfo } = user;
  const userName = personalInfo?.name;
  const [cookies] = useCookies(['userToken']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const userRef = useRef(null);
  const settingsButtonRef = useRef(null);

  const renderLoginButton = () => {
    return (
      <StyledTextButton
        startIcon={<FiUser size={24} />}
        component={Link}
        to='/login'
        sx={{
          minWidth: { xs: '44px' },
          '& .MuiButton-startIcon': {
            marginRight: { xs: 0, md: 1 },
          },
        }}
      >
        <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>Login</Typography>
      </StyledTextButton>
    );
  };

  return (
    <Grid2
      component='nav'
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {!!cookies.userToken && role === 'customer' && (
        <Box>
          <Button
            title='Definições'
            ref={settingsButtonRef}
            startIcon={<FiSettings size={24} />}
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
            sx={{
              minWidth: 'initial',
              '& .MuiButton-startIcon': {
                marginRight: 0,
              },
            }}
          />
          <Menu
            id='settings-menu'
            anchorEl={settingsButtonRef.current}
            open={isSettingsOpen}
            onClose={() => setIsSettingsOpen(false)}
          >
            <MenuItem>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={pvprIsVisible} />}
                  title='Mostrar Preço'
                  label='Mostrar Preço'
                  slotProps={{ typography: { variant: 'small' } }}
                  onChange={() => dispatch(setPvprVisibility(!pvprIsVisible))}
                  sx={{ marginRight: 0 }}
                />
              </FormGroup>
            </MenuItem>
          </Menu>
        </Box>
      )}

      <StyledTextButton
        startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to='/carrinho'
        sx={{
          minWidth: 'initial',
          gap: '8px',
          '& .MuiButton-startIcon': {
            marginRight: 0,
          },
        }}
      >
        {cart.length && user.role ? (
          <Badge
            color='danger'
            badgeContent={cart.reduce((prev, item) => prev + item.quantity, 0)}
            sx={{ top: '-10px' }}
          />
        ) : (
          <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>Carrinho</Typography>
        )}
      </StyledTextButton>

      {!cookies.userToken ? (
        renderLoginButton()
      ) : (
        <>
          {ACCOUNT_LINKS.map(({ title, url, icon }) => {
            const accountButton = title === 'User';

            return (
              <HeaderAccountButton
                key={title}
                startIcon={icon}
                href={!accountButton ? url : null}
                ref={accountButton ? userRef : null}
                onClick={accountButton ? () => setIsMenuOpen(true) : null}
                sx={{
                  gap: '8px',
                  '& .MuiButton-startIcon': {
                    marginRight: 0,
                  },
                }}
              >
                <HeaderAccountButtonText sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {accountButton ? formatTextToTitleCase(userName.split(' ')[0]) : title}
                </HeaderAccountButtonText>
              </HeaderAccountButton>
            );
          })}

          <UserMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            userRef={userRef}
          />
        </>
      )}
    </Grid2>
  );
};

export default AccountLinks;
