import { Box, Container, Icon, Typography, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiGrid, FiList } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';
import ProductFilter from '../components/ProductFilter';
import ProductFilterPopover from '../components/ProductFilterPopover';
import ProductSortBy from '../components/ProductSortBy';
import GridView from '../components/ProductsPage/GridView';
import ListView from '../components/ProductsPage/ListView';
import { useLocalStorage } from '../helpers';
import { setProducts } from '../state/productsSlice';
import { MainContainer } from '../styles';
import { MEDIUM_DEVICES_BREAKPOINT, NOT_FOUND_ROUTE } from '../variables';

const Campaigns = () => {
  const SMALL_DEVICES = useMediaQuery(
    `(max-width:${MEDIUM_DEVICES_BREAKPOINT})`
  );
  const sortBy = useSelector((state) => state.products.sortBy);
  const customerId = useSelector((state) => state.user.selectedClient.id);
  const products = useSelector((state) => state.products.products);
  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;
  const [layout, setLayout] = useLocalStorage('layout', 'grid');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categorySlug, subcategorySlug, productTypesSlugs } = useParams();
  let [searchParams] = useSearchParams();
  const [availableFilters, setAvailableFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const productCategoriesParams = {
    category: categorySlug,
    subcategories: subcategorySlug,
    productTypes: productTypesSlugs,
  };

  //  update 'search params' values to array
  const searchParamsEntries = Object.values([...searchParams]).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value.split(',') }),
    {}
  );

  //  merge 'search params' & 'product categories slugs'
  //  remove entrie if it has no 'value'
  //  IF 'key' exists » concat new 'value'
  const productParamsMerged = Object.entries({
    ...productCategoriesParams,
    limit: '20',
  }).reduce(
    (acc, [key, value]) =>
      value
        ? {
            ...acc,
            [key]: acc?.[key] ? acc?.[key]?.concat(value) : value.split(','),
          }
        : { ...acc },
    { ...searchParamsEntries }
  );

  useEffect(() => {
    setIsLoading(true);
    getProducts({
      sortBy: 'date:desc',
      productFilters: productParamsMerged,
    })
      .then(({ displayedProducts, displayedFilters }) => {
        dispatch(setProducts(displayedProducts));

        const filters = displayedFilters.map((filter) => ({
          ...filter,
          options: Array.isArray(filter.options)
            ? filter.options
            : [filter.options],
        }));

        setAvailableFilters(filters);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 404) navigate(`/${NOT_FOUND_ROUTE}`);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categorySlug,
    subcategorySlug,
    productTypesSlugs,
    sortBy,
    searchParams,
    dispatch,
  ]);

  const categoriesHierarchy = availableFilters.filter(
    (filter) =>
      filter.id === 'category' ||
      filter.id === 'subcategories' ||
      filter.id === 'productTypes'
  );

  const availableFiltersFiltered = availableFilters.filter(
    (filter) => filter.options.length !== 0
  );

  return (
    <MainContainer
      sx={{
        paddingTop: 2,
      }}
    >
      <Container>
        {!!categoriesHierarchy.length && (
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
          >
            <Grid xs={12}>
              <Breadcrumbs categories={categoriesHierarchy} />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          sx={{
            marginTop: 2,
          }}
        >
          {!SMALL_DEVICES && (
            <Grid md={3}>
              {/* show filters on the side in big screens */}
              {availableFiltersFiltered.map((filter) => (
                <ProductFilter
                  key={filter.id}
                  filter={filter}
                  activeFilters={productParamsMerged}
                />
              ))}
            </Grid>
          )}
          <Grid
            md={8.5}
            mdOffset={0.5}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  marginBottom: { xs: '0', md: '30px' },
                }}
              >
                {/* attention to subcategory title */}
                <Box
                  sx={{
                    flex: '1',
                  }}
                >
                  <Typography
                    variant='h5'
                    sx={{
                      flex: { lg: '1' },
                      flexBasis: { md: '100%', lg: 'initial' },
                      fontSize: '1.3rem',
                      marginBottom: { md: 2, lg: 0 },
                    }}
                  >
                    Novidades
                    <Typography
                      variant='overline'
                      sx={{
                        display: 'block',
                        lineHeight: '1.5',
                        textTransform: 'initial',
                      }}
                    >
                      ({products.length} resultados)
                    </Typography>
                  </Typography>
                </Box>

                {!SMALL_DEVICES && (
                  <>
                    <Box
                      sx={{
                        marginLeft: '10px',
                        display: 'flex',
                        gap: '6px',
                      }}
                    >
                      <Icon
                        title='Ver em grelha'
                        sx={{
                          backgroundColor: layout === 'grid' && '#D9D9D9',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer',
                          transition: 'background-color 200ms ease',
                          '&:hover': {
                            backgroundColor: '#D9D9D9',
                          },
                        }}
                        onClick={() => setLayout('grid')}
                      >
                        <FiGrid size={16} />
                      </Icon>

                      <Icon
                        title='Ver em lista'
                        sx={{
                          backgroundColor: layout === 'list' && '#D9D9D9',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer',
                          transition: 'background-color 200ms ease',
                          '&:hover': {
                            backgroundColor: '#D9D9D9',
                          },
                        }}
                        onClick={() => setLayout('list')}
                      >
                        <FiList size={16} />
                      </Icon>
                    </Box>
                  </>
                )}
              </Box>

              {SMALL_DEVICES && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '15px',
                    marginBottom: '10px',
                  }}
                >
                  <ProductFilterPopover
                    availableFilters={availableFiltersFiltered}
                    activeFilters={productParamsMerged}
                  />

                  <ProductSortBy />
                </Box>
              )}

              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {!products.length ? (
                    <Typography variant='h6'>{`Não foram encontrados produtos. Utilize uma pesquisa diferente.`}</Typography>
                  ) : (
                    <>
                      {layout === 'grid' ? (
                        <GridView
                          products={products}
                          userIsLogged={userIsLogged}
                        />
                      ) : (
                        <ListView
                          products={products}
                          customerId={customerId}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default Campaigns;
