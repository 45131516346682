import React from 'react';
import ProductListCard from './ProductListCard';

const ListView = ({ products, customerId }) => {
  return products.map((product, index) => (
    <ProductListCard
      key={`${product.title}-${index}`}
      product={{ ...product }}
      customerId={customerId}
    />
  ));
};

export default ListView;
