import { Box, Divider, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { StyledFormInputLabel, StyledTextButton } from '../../styles';
import { LOGIN_FORM_TITLE, LOGIN_PASS_RECOVERY_TEXT, LOGIN_REGISTER_TEXT } from '../../variables';
import LoginForm from './LoginForm';

const Login = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginY: { xs: '20px', sm: '60px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <StyledFormInputLabel sx={{ textAlign: 'center', marginBottom: 1 }}>{LOGIN_FORM_TITLE}</StyledFormInputLabel>

      <LoginForm />

      <Link
        component={RouterLink}
        to='recuperar-palavra-passe'
        variant='caption'
        sx={{ fontWeight: 700, textAlign: 'center' }}
      >
        {LOGIN_PASS_RECOVERY_TEXT}
      </Link>

      <Divider sx={{ width: '100%', marginY: '20px' }} />

      <Typography sx={{ textAlign: 'center' }}>{LOGIN_REGISTER_TEXT}</Typography>

      <StyledTextButton
        component={RouterLink}
        to='registo-utilizador'
        variant='contained'
        title='Novo registo'
      >
        Novo registo
      </StyledTextButton>
    </Box>
  );
};

export default Login;
