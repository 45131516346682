import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapProduct } from './mapApiInterface';

//  GET

function getCart(cartId) {
  return axios.get(`${API_URL}/cart/${cartId}`, getHeaders()).then(({ data: { cart } }) => {
    const { cart_items } = cart;
    const mappingProducts = cart_items?.map((product) => mapProduct(product));
    return { ...cart, cart_items: mappingProducts };
  });
}

function getUser() {
  return axios.get(`${API_URL}/user`, getHeaders()).then(({ data: { user } }) => user);
}

function getUsers() {
  return axios.get(`${API_URL}/user/users`, getHeaders()).then(({ data: { users } }) => users);
}

function getPreviousOrders(customerId) {
  return axios.get(`${API_URL}/orders?customerId=${customerId}`, getHeaders()).then(({ data: { orders } }) => orders);
}

function getReceipts(customerId) {
  return axios.get(`${API_URL}/orders/account?customerId=${customerId}`, getHeaders()).then(({ data: { account } }) => account);
}

function getShoppingLists() {
  return axios.get(`${API_URL}/shopping-lists`, getHeaders()).then(({ data: { lists } }) => lists);
}

function getProductUserHistory(customerId) {
  return axios.get(`${API_URL}/user/product-history/${customerId}`, getHeaders());
}

// POST

function setCustomer(customerId) {
  return axios
    .post(`${API_URL}/user/customers/select-customer`, customerId, getHeaders())
    .then(({ data: { message } }) => message);
}

function addUser(newUserData) {
  return axios.post(`${API_URL}/user/create/user`, newUserData, getHeaders()).then(({ data: { addresses } }) => addresses);
}

function updateUser(newUserData) {
  return axios.post(`${API_URL}/user/update/user`, newUserData, getHeaders()).then(({ data: user }) => user);
}

function addAddress(newDeliveryAddress) {
  return axios
    .post(`${API_URL}/user/create/address`, newDeliveryAddress, getHeaders())
    .then(({ data: { addresses } }) => addresses);
}

function updateAddress(updatedAddress) {
  return axios.post(`${API_URL}/user/update/address`, updatedAddress, getHeaders()).then(({ data: { addresses } }) => addresses);
}

function addShoppingList(name) {
  return axios.post(`${API_URL}/shopping-lists/create`, { name }, getHeaders()).then(({ data: { lists } }) => lists);
}

function deleteShoppingList(id) {
  return axios.post(`${API_URL}/shopping-lists/${id}/delete`, {}, getHeaders()).then(({ data: { lists } }) => lists);
}

function deleteProductFromShoppingList(listId, productId) {
  return axios
    .post(`${API_URL}/shopping-lists/${listId}/remove`, { product_id: productId }, getHeaders())
    .then(({ data: { lists } }) => lists);
}

function addProductToUserHistory(reference, customerId) {
  return axios.post(`${API_URL}/user/product-history`, { reference, userId: customerId }, getHeaders()).then(({ data }) => data);
}

function duplicateOrder(customerId, orderId) {
  return axios.post(`${API_URL}/orders/duplicate`, { customerId, orderId }, getHeaders()).then(({ data }) => data);
}

export {
  getCart,
  getUser,
  getUsers,
  getPreviousOrders,
  getReceipts,
  getShoppingLists,
  setCustomer,
  addUser,
  updateUser,
  addAddress,
  updateAddress,
  addShoppingList,
  deleteShoppingList,
  deleteProductFromShoppingList,
  getProductUserHistory,
  addProductToUserHistory,
  duplicateOrder,
};
