const STORES = [
  {
    title: 'IMPORAUDIO LDA.',

    address: {
      street: 'Rua do Padrão 76-140,',
      zipCode: '4455-267 Perafita,',
      city: 'Matosinhos',
      country: 'Portugal',
    },

    phone: '+351 229 966 738',

    mobile: '',

    fax: '+351 229 966 741',

    email: 'imporaudio@imporaudio.com',
  },
];

export default STORES;
