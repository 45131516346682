import React from 'react';
import ReactElasticCarousel from 'react-elastic-carousel';
import ProductCard from './ProductCard';
import './ProductCarousel.css';

const ProductCarousel = ({
  products,
  breakpoints = [
    { width: 0, itemsToShow: 1 },
    { width: 400, itemsToShow: 2 },
    { width: 600, itemsToShow: 3 },
    { width: 800, itemsToShow: 4 },
    { width: 1000, itemsToShow: 5 },
  ],
}) => {
  return (
    <ReactElasticCarousel
      breakPoints={breakpoints}
      itemPadding={[10]}
      pagination={false}
    >
      {products.map((product, index) => (
        <ProductCard
          key={`${product.reference}-${index}`}
          product={product}
          showStockAvailability
        />
      ))}
    </ReactElasticCarousel>
  );
};

export default ProductCarousel;
