import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';

const ProductDetailsTable = ({ params }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {params.length !== 0 ? (
            params.map(({ title, value }, index) => {
              return (
                value && value !== 'undefined' && (
                  <TableRow
                    key={`technical-detail-${index}`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell>{title}</TableCell>

                    <TableCell sx={{ fontWeight: 600 }}>{value}</TableCell>
                  </TableRow>
                )
              );
            })
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  padding: '20px',
                }}
              >
                Não existem detalhes técnicos disponíveis.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductDetailsTable;
