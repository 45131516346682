import { Box } from '@mui/material';
import { StyledTextfield } from '../../../styles';

const PersonalInfo = ({ handleChange, isReadOnly, userData }) => {
  return (
    <Box>
      <StyledTextfield
        fullWidth
        type='text'
        name='name'
        label='Nome'
        value={userData.name}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />

      <StyledTextfield
        fullWidth
        label='Telemóvel'
        type='text'
        name='phone'
        value={userData.phone}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />

      <StyledTextfield
        fullWidth
        label='Email'
        type='email'
        name='email'
        value={userData.email}
        onChange={handleChange}
        inputProps={{ readOnly: isReadOnly }}
        error={!isReadOnly}
      />
    </Box>
  );
};

export default PersonalInfo;
