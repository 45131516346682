import { Box, Typography, useMediaQuery, Grid, Container } from '@mui/material';
import React from 'react';
import { COMPANY_INFO, MEDIUM_DEVICES_BREAKPOINT } from '../../variables';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CustomerSupport from './CustomerSupport';
import FooterMobileAccordion from './FooterMobileAccordion';
import SocialMedia from './SocialMedia';

const Footer = () => {
  const LAPTOP_DEVICES = useMediaQuery(
    `(min-width:${MEDIUM_DEVICES_BREAKPOINT})`
  );
  const currentYear = new Date().getFullYear();

  return (
    <Box>
      <Box
        sx={{
          marginTop: { xs: '30px', md: '40px' },
          paddingY: '40px',
          backgroundColor: '#F5F5F8',
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={{ xs: 0, md: 5 }}>
            <SocialMedia />

            {!LAPTOP_DEVICES ? (
              <FooterMobileAccordion />
            ) : (
              <>
                <AboutUs />

                <CustomerSupport />

                <ContactUs />
              </>
            )}
          </Grid>
        </Container>
      </Box>

      <Typography align='center' sx={{ paddingY: '20px', fontSize: '1em' }}>
        {COMPANY_INFO.name} © {currentYear}.&nbsp;
        <Typography
          component='a'
          href='https://www.bdcadigital.com/ecommerce-b2b/'
          title='Powered by BDCA Digital'
          variant='caption'
          fontWeight={700}
          target='_blank'
          sx={{
            color: 'black',
            textDecoration: 'none',
            transition: 'opacity 200ms ease',
            '&:hover': { opacity: '.75' },
          }}
        >
          B2B Platform by BDCA Digital
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;
