import { Container, Typography } from '@mui/material';
import ProductCarousel from '../ProductCarousel';

const Highlights = ({ highlights }) => {
  return (
    <Container sx={{ marginTop: '60px' }}>
      <Typography variant='h5'>Artigos em destaque</Typography>
      <Typography sx={{ marginTop: 1, marginBottom: 2 }}>
        Aqui vai encontrar uma seleção de produtos que recomendamos.
      </Typography>

      <ProductCarousel products={highlights} />
    </Container>
  );
};

export default Highlights;
