import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { StyledTextfield } from '../../../styles';

const SellerInfo = () => {
  const sellerInfo = useSelector((state) => state.user.sellerInfo);

  const { name, email, phone } = sellerInfo;

  return (
    <Box>
      <StyledTextfield
        label='Nome'
        defaultValue={name}
        disabled
        fullWidth
      />
      <StyledTextfield
        label='Email'
        defaultValue={email}
        disabled
        fullWidth
      />
      <StyledTextfield
        label='Telemóvel'
        defaultValue={phone}
        disabled
        fullWidth
      />
    </Box>
  );
};

export default SellerInfo;
