import { AccordionDetails, Box, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledFormInputLabel,
} from '../../styles';
import { COMPANY_INFO, PAGES_SLUGS } from '../../variables';
import { FiPlus } from 'react-icons/fi';

const FooterMobileAccordion = () => {
  const {
    address: { street, zipCode, city },
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ margin: '30px auto 0 auto' }}>
      <StyledAccordion
        expanded={expanded === 'aboutUs'}
        onChange={handleChange('aboutUs')}
      >
        <StyledAccordionSummary
          aria-controls='aboutUs-content'
          id='aboutUs-header'
          expandIcon={<FiPlus />}
        >
          <StyledFormInputLabel>
            Mais sobre a {COMPANY_INFO.name}
          </StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: 1,
            },
          }}
        >
          <Typography
            component={RouterLink}
            to='/page/quem-somos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Quem Somos
          </Typography>

          <Typography
            component={RouterLink}
            to='/page/contactos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Contactos
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === 'customerSupport'}
        onChange={handleChange('customerSupport')}
      >
        <StyledAccordionSummary
          aria-controls='customerSupport-content'
          id='customerSupport-header'
          expandIcon={<FiPlus />}
        >
          <StyledFormInputLabel>Apoio ao cliente</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: 1,
            },
          }}
        >
          <Typography
            component={RouterLink}
            to={PAGES_SLUGS.termsAndConditions}
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Termos e Condições
          </Typography>

          <Typography
            component={RouterLink}
            to={PAGES_SLUGS.privacyPolicy}
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Política de Privacidade
          </Typography>

          <Typography>
            <Link
              href='https://www.livroreclamacoes.pt/Inicio/'
              target='_blank'
              rel='noreferrer'
              underline='none'
              title='Livro de Reclamações'
            >
              Livro de Reclamações
            </Link>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        expanded={expanded === 'contactUs'}
        onChange={handleChange('contactUs')}
      >
        <StyledAccordionSummary
          aria-controls='contactUs-content'
          id='contactUs-header'
          expandIcon={<FiPlus />}
        >
          <StyledFormInputLabel>Contacte-nos</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: 1,
            },
          }}
        >
          <Typography>
            {street} <br /> {zipCode}
            <br />
            {city}
          </Typography>

          <Box sx={{ marginBottom: 1 }}>
            <Typography
              component={'a'}
              href={`tel:${phone}`}
              sx={{
                display: 'block',
                color: 'black',
                textDecoration: 'none',
                marginBottom: '0 !important',
              }}
            >
              <strong>Telf.</strong> {phone}
            </Typography>
            <Typography variant='caption'>{`(Chamada para a rede fixa nacional)`}</Typography>
          </Box>

          <Typography>
            <strong>Fax.</strong> {fax}
          </Typography>

          <Typography
            component={'a'}
            href={`mailto:${email}`}
            sx={{ display: 'block', color: 'black', textDecoration: 'none' }}
          >
            <strong>Email.</strong> {email}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FooterMobileAccordion;
