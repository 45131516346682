import { Container, useMediaQuery } from '@mui/material';
import React from 'react';
// import { useDispatch } from 'react-redux';
import { Outlet /* useNavigate */ } from 'react-router-dom';
// import { getUser } from '../api/user';
import UserAccountNavBar from '../components/UserAccount/UserAccountNavBar';
// import {
//   setAddresses,
//   setCompanyInfo,
//   setPersonalInfo,
//   setSellerInfo,
// } from '../state/userSlice';
import { MEDIUM_DEVICES_BREAKPOINT } from '../variables';
import { MainContainer } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';

const AccountPage = () => {
  const SMALL_DEVICES = useMediaQuery(`(max-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   getUser()
  //     .then((user) => {

  //       const { personalInfo, companyInfo, addresses, sellerInfo } = user;

  //       dispatch(setPersonalInfo(personalInfo));
  //       dispatch(setCompanyInfo(companyInfo));
  //       dispatch(setAddresses(addresses));
  //       // trocar test por dados da api, importar consequentes funções ou dados (se houver necessidade), organizar imports automaticamente usando option+shift+o
  //       dispatch(setSellerInfo(sellerInfo));
  //     })
  //     .catch(({ response: { status } }) => {
  //       if (status === 401) navigate('/login');
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          <Grid
            md={3}
            lg={2}
          >
            <UserAccountNavBar SMALL_DEVICES={SMALL_DEVICES} />
          </Grid>
          <Grid
            xs={12}
            md={9}
            lg={9.5}
            lgOffset={0.5}
          >
            <Outlet context={[SMALL_DEVICES]} />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default AccountPage;
