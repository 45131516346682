import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiFacebook, FiInstagram, FiLinkedin } from 'react-icons/fi';
import Logo from '../../assets/companyLogo.png';
import { ACCENT_COLOR, COMPANY_INFO } from '../../variables';

const SocialMedia = () => {
  return (
    <Grid item xs={12} md={3}>
      <Box
        sx={{
          display: { xs: 'flex', md: 'block' },
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '100%', maxWidth: '234px' }}>
          <img src={Logo} alt='Imporaudio' width='100%' />
        </Box>

        <Box sx={{ marginTop: '20px' }}>
          <Typography variant='caption' paragraph sx={{ marginBottom: '10px', textAlign: { xs: 'center', md: 'left' } }}>
            Fique ligado!
          </Typography>

          <IconContext.Provider
            value={{
              color: ACCENT_COLOR,
            }}
          >
            <Link
              href={COMPANY_INFO.social.linkedin}
              target='_blank'
              title='Linkedin'
              rel='noreferrer'
              underline='none'
              sx={{ marginRight: '5px', marginLeft: { xs: '5px', md: 0 } }}
            >
              <FiLinkedin />
            </Link>

            <Link
              href={COMPANY_INFO.social.facebook}
              target='_blank'
              title='Facebook'
              rel='noreferrer'
              underline='none'
              sx={{ marginRight: '5px', marginLeft: '5px' }}
            >
              <FiFacebook />
            </Link>

            <Link
              href={COMPANY_INFO.social.instagram}
              target='_blank'
              title='Instagram'
              rel='noreferrer'
              underline='none'
              sx={{ marginRight: '5px', marginLeft: '5px' }}
            >
              <FiInstagram />
            </Link>
          </IconContext.Provider>
        </Box>
      </Box>
    </Grid>
  );
};

export default SocialMedia;
