import { Autocomplete, IconButton, MenuItem } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { getProducts } from '../../api/products';
import { StyledSearchTextfield } from '../../styles';
import { PRIMARY_COLOR } from '../../variables';

const SEARCH_TEXT = 'Ver todos os resultados de ';

const SEARCH_ID = 'search';

const SearchBar = () => {
  const [dropdownProducts, setDropdownProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();

  // api request with limit = 5
  //   const newProducts = await getProducts({ input }).then(
  //     ({ displayedProducts }) => displayedProducts
  //   );

  //   setDropdownProducts(newProducts);
  // }, 500);

  const getOptionsDebounced = debounce(async (input) => {
    const newProducts = await getProducts({ input }).then(({ displayedProducts }) => displayedProducts);
    setDropdownProducts(newProducts);
  }, 500);

  useEffect(() => {
    const filteredProducts = dropdownProducts
      .filter(({ title, reference }) => {
        return (
          title.toLowerCase().includes(inputValue.toLowerCase()) ||
          reference.toLowerCase().includes(inputValue.toLowerCase())
        );
      })
      .slice(0, 4);

    const mappedProducts = filteredProducts.map(({ _id, title }) => ({
      label: title,
      id: _id,
    }));

    const searchFor = { label: `${SEARCH_TEXT}${inputValue}`, id: SEARCH_ID };

    const shownProducts = inputValue ? [...mappedProducts, searchFor] : [];

    setShownProducts(shownProducts);
  }, [dropdownProducts, inputValue]);

  function handleInputChange(e, newInputValue, reason) {
    if (reason === 'reset') {
      setInputValue('');
      return;
    }

    const replacedInputValue = newInputValue.replace(SEARCH_TEXT, '');

    setInputValue(replacedInputValue);

    if (replacedInputValue.length < 4) return;

    getOptionsDebounced(replacedInputValue);
  }

  function navigateToSearchPage(id) {
    if (id === SEARCH_ID && inputValue) {
      navigate({
        pathname: '/pesquisa',
        search: createSearchParams({
          query: inputValue,
        }).toString(),
      });

      setInputValue('');
    }
  }

  function handleClickOnItem(id) {
    navigateToSearchPage(id);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') {
      navigateToSearchPage(SEARCH_ID);
    }
  }

  function handleIconClick() {
    navigateToSearchPage(SEARCH_ID);
  }

  return (
    <Grid2
      xs={12}
      sm={4}
      sx={{
        height: { xs: '40px', md: '54px' },
        padding: '10px',
        borderRadius: '40px',
        backgroundColor: '#6f6f6f90',
        display: 'flex',
        alignItems: 'center',
        marginTop: { xs: '10px', sm: 0 },
      }}
    >
      <Autocomplete
        options={shownProducts}
        renderInput={(params) => (
          <StyledSearchTextfield
            {...params}
            label='O que está à procura?'
            onKeyPress={handleKeyPress}
          />
        )}
        disableClearable
        freeSolo
        fullWidth
        inputValue={inputValue}
        onInputChange={handleInputChange}
        size='small'
        renderOption={(params, { id, label }) => (
          <MenuItem
            {...params}
            key={id}
            component={id !== SEARCH_ID ? Link : null}
            to={`produtos/${id}`}
            onClickCapture={() => handleClickOnItem(id)}
          >
            {label}
          </MenuItem>
        )}
      />

      <IconButton
        onClick={handleIconClick}
        sx={{ color: PRIMARY_COLOR }}
      >
        <FiSearch size={24} />
      </IconButton>
    </Grid2>
  );
};

export default SearchBar;
