import { Divider, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../../../api/login';
import { ACCOUNT_LINKS } from '../../../variables';
import { setCart, setCartTotal } from '../../../state/cartSlice';
import {
  setPvprVisibility,
  setRole,
  setSelectedClient,
  setSellerInfo,
} from '../../../state/userSlice';

const UserMenu = ({ isMenuOpen, setIsMenuOpen, userRef }) => {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);

  const userRole = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  function handleLogout() {
    setIsMenuOpen(false);

    logout().then(() => {
      //  remove user token
      removeCookie('userToken', { path: '/' });
      //  reset seller info
      dispatch(setSellerInfo({}));
      //  reset selected client
      dispatch(setSelectedClient(null));
      //  reset pvpr price visibility
      dispatch(setPvprVisibility(false));
      //  reset total cart
      dispatch(setCartTotal(0));
      //  reset cart items
      dispatch(setCart([]));
      //  reset user role
      dispatch(setRole(''));
    });
  }

  return (
    <Menu
      anchorEl={userRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
    >
      {userRole === 'seller' ? (
        <div>
          <MenuItem
            component={Link}
            to='/conta/info'
            onClick={() => setIsMenuOpen(false)}
          >
            <Typography fontSize={14}>Os meus dados</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            component={Link}
            to='/conta/encomendas'
            onClick={() => setIsMenuOpen(false)}
          >
            <Typography fontSize={14}>Encomendas</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            component={Link}
            to='/conta/conta-corrente'
            onClick={() => setIsMenuOpen(false)}
          >
            <Typography fontSize={14}>Conta Corrente</Typography>
          </MenuItem>
          <Divider />
          <MenuItem
            component={Link}
            to='/login'
            onClick={handleLogout}
          >
            <Typography fontSize={14}>Terminar sessão</Typography>
          </MenuItem>
        </div>
      ) : (
        ACCOUNT_LINKS.find((obj) => obj.title === 'User').submenu.map(
          ({ title, url }) => {
            const logoutButton = title === 'Terminar sessão';

            return (
              <div key={title}>
                <MenuItem
                  component={Link}
                  to={url}
                  onClick={
                    logoutButton ? handleLogout : () => setIsMenuOpen(false)
                  }
                >
                  <Typography fontSize={14}>{title}</Typography>
                </MenuItem>

                {!logoutButton && <Divider />}
              </div>
            );
          }
        )
      )}
    </Menu>
  );
};

export default UserMenu;
