import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPreviousOrders, getReceipts } from '../../../api/user';
import { formatCurrency } from '../../../constants/utils';
import { setPreviousOrders, setReceipts } from '../../../state/userSlice';
import Loading from '../../Loading';
import ExportToExcelButton from './ExportToExcelButton';
import OrdersTable from './OrdersTable';
import PurchasesFilters from './PurchasesFilters';
import ReceiptsTable from './ReceiptsTable';

const AccountPurchases = ({ props: { mode } }) => {
  // entities can be orders or receipts
  const isOrders = mode === 'orders';

  const [entities, setEntities] = useState({ orders: [], receipts: [] });

  const orders = useSelector((state) => state.user.orders);

  const receipts = useSelector((state) => state.user.receipts);

  const [selectedStatus, setSelectedStatus] = useState('all');

  const [selectedYear, setSelectedYear] = useState('all');

  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const customerId = user?.selectedClient?.id || user?.id;

  useEffect(() => {
    setIsLoading(true);

    getPreviousOrders(customerId)
      .then((orders) => {
        dispatch(setPreviousOrders(orders.reverse()));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    getReceipts(customerId)
      .then((receipts) => {
        dispatch(setReceipts(receipts));
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, customerId]);

  useEffect(() => {
    const entities = { orders, receipts };

    setEntities({ ...entities });

    setSelectedStatus('all');

    setSelectedYear('all');
  }, [mode, orders, receipts]);

  const detailsMode = isOrders ? entities.orders : entities.receipts;

  const shownEntities = [...detailsMode];

  const filteredByStatus = shownEntities.filter(({ status }) => {
    if (selectedStatus === 'all') return true;

    return selectedStatus === status;
  });

  const filteredByYearAndStatus = filteredByStatus.filter(({ date }) => {
    if (selectedYear === 'all') return true;

    return selectedYear === new Date(date).getFullYear();
  });

  const filteredByEverything = filteredByYearAndStatus.filter(({ reference }) =>
    String(reference).includes(search)
  );

  //  receipts balances
  let invoicedAmounts = 0;
  let openAmounts = 0;
  if (!!entities.receipts.length) {
    invoicedAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.value).toFixed(2)),
      0
    );
    openAmounts = entities.receipts?.reduce(
      (acc, invoice) =>
        parseFloat(Math.fround(acc + invoice?.balance).toFixed(2)),
      0
    );
  }

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        gutterBottom
        sx={{
          fontWeight: 700,
          textTransform: 'uppercase',
          marginBottom: '30px',
        }}
      >
        {isOrders ? 'Encomendas' : 'Conta Corrente'}
      </Typography>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!isOrders && !!entities.receipts.length && (
            <Box
              sx={{
                display: 'grid',
                gap: 0.5,
                marginBottom: 4,
              }}
            >
              <Typography variant='small'>
                Valores Faturados:
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600,
                  }}
                >
                  {formatCurrency(invoicedAmounts)}
                </Typography>
              </Typography>
              <Typography variant='small'>
                Valores Pendentes:
                <Typography
                  component='span'
                  sx={{
                    marginLeft: 1.5,
                    fontWeight: 600,
                  }}
                >
                  {formatCurrency(openAmounts)}
                </Typography>
              </Typography>
            </Box>
          )}

          <PurchasesFilters
            type={detailsMode}
            selectedStatus={selectedStatus}
            selectedYear={selectedYear}
            setSelectedStatus={setSelectedStatus}
            setSelectedYear={setSelectedYear}
            search={search}
            setSearch={setSearch}
          />

          {filteredByEverything.length !== 0 && (
            <Box>
              {isOrders ? (
                <OrdersTable shownOrders={filteredByEverything} />
              ) : (
                <ReceiptsTable receipts={filteredByEverything} />
              )}
              <ExportToExcelButton isOrders={isOrders} />
            </Box>
          )}

          {filteredByEverything.length === 0 && (
            <Typography sx={{ marginY: '20px' }}>
              Não há registos para mostrar.
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountPurchases;
