import { Box, Snackbar, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import { formatCurrency } from '../../../constants/utils';
import { setCartTotal, setDiscount, updateCart } from '../../../state/cartSlice.js';
import { StyledTextButton } from '../../../styles';
import { ACCENT_COLOR } from '../../../variables';
import QuantitySelector from './QuantitySelector';

const ProductCheckout = ({ product, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const { role, pvprIsVisible } = user;

  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;

  const [productsForCart, setProductsForCart] = useState(new Map());
  const [quantity, setQuantity] = useState(1);
  const [addToCartConfirmation, setAddToCartConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const showPrice = {
    pvpr: userIsLogged,
    pvp: userIsLogged && ((role === 'customer' && pvprIsVisible) || role === 'seller'),
  };

  const handleProductSelect = useCallback(
    (product) => {
      const newProductsForCart = new Map(productsForCart);

      newProductsForCart.set(product.reference, product);
      setProductsForCart(newProductsForCart);
    },
    [productsForCart]
  );

  function addToCart() {
    const products = Array.from(productsForCart.values());
    const newProducts = products.filter(({ quantity }) => quantity > 0);

    addProductsToCart(newProducts, customerId)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));
        setAddToCartConfirmation(true);
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setErrorMessage(msg);
            console.error(`Error while adding product to cart at ProductCheckout: ${msg}`);
          }
        }
      );

    setAddToCartConfirmation(false);
  }

  useEffect(() => {
    product.quantity = quantity;

    handleProductSelect(product);
  }, [handleProductSelect, product, quantity]);

  return (
    <Box>
      <Box
        sx={{
          display: { xs: 'grid', sm: 'flex' },
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        gap={{ xs: 4, md: 6 }}
      >
        <Box>
          <Typography
            gutterBottom
            fontWeight={700}
          >
            Quantidade
          </Typography>

          <QuantitySelector
            product={product}
            quantity={{ value: quantity, setQuantity }}
            selectForCart={handleProductSelect}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: { xs: 4, md: 6 },
            order: { xs: -1, sm: 1 },
          }}
        >
          {showPrice.pvpr && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                gutterBottom
                fontWeight={700}
              >
                PVPR
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: '1.2rem', sm: '1.3rem' },
                  fontWeight: 300,
                }}
              >
                {formatCurrency(product.pvpPrice)}
              </Typography>
            </Box>
          )}

          {showPrice.pvp && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                gutterBottom
                fontWeight={700}
              >
                PREÇO
              </Typography>

              {product.onSale ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: '1.2rem', sm: '1.3rem' },
                      fontWeight: 600,
                      textDecoration: 'line-through',
                    }}
                  >
                    {formatCurrency(product.price)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '1.2rem', sm: '1.3rem' },
                      fontWeight: 600,
                      color: 'danger.main',
                    }}
                  >
                    {formatCurrency(product.salePrice)}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: '1.3rem',
                    fontWeight: 600,
                    color: 'danger.main',
                  }}
                >
                  {formatCurrency(product.price)}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Box marginTop={4}>
        <StyledTextButton
          variant='contained'
          title='Adicionar ao carrinho'
          onClick={addToCart}
        >
          Adicionar ao Carrinho
        </StyledTextButton>
      </Box>

      {addToCartConfirmation && (
        <Snackbar
          autoHideDuration={4000}
          message={addToCartConfirmation ? 'Adicionado ao carrinho' : errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={addToCartConfirmation}
          ContentProps={{
            sx: {
              backgroundColor: addToCartConfirmation ? '#4FD290' : ACCENT_COLOR,
            },
          }}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setAddToCartConfirmation(false);
          }}
        />
      )}
    </Box>
  );
};

export default ProductCheckout;
