import { Box, Card, CardMedia, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getCampaigns } from '../../api';
import { NOT_FOUND_ROUTE } from '../../variables';
import Loading from '../Loading';

const HighlightedCampaigns = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [campaignsList, setCampaignsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getCampaigns()
      .then((campaigns) => {
        setCampaignsList(campaigns);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 404) navigate(`/${NOT_FOUND_ROUTE}`);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
    >
      {isLoading ? (
        <Grid xs={12}>
          <Loading />
        </Grid>
      ) : (
        !!campaignsList.length &&
        campaignsList.map(({ id, name, description, image, url }) => (
          <Grid
            key={`campaign-${id}`}
            xs={12}
            md={campaignsList.length !== 1 ? 6 : 12}
          >
            <Box component='section'>
              <Card
                component={Link}
                to={url}
                title={name}
                sx={{
                  position: 'relative',
                  borderRadius: '0',
                  boxShadow: 'none',
                  '&:hover': {
                    '.MuiCardMedia-root': {
                      filter: ' brightness(75%)',
                    },
                  },
                }}
              >
                <CardMedia
                  component='img'
                  image={image}
                  sx={{
                    filter: ' brightness(70%)',
                    transition: 'filter 200ms ease',
                    height: { xs: '200px', md: '300px' },
                  }}
                />

                <Box
                  component='div'
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: { xs: theme.spacing(3), md: theme.spacing(4) },
                    width: '100%',
                    maxWidth: '50%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    color: 'white',
                  }}
                >
                  <Typography
                    variant='h1'
                    sx={{
                      fontSize: { xs: '1.4em', md: '1.75em' },
                      color: 'inherit',
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '0.95em', md: '1em' },
                      color: 'inherit',
                      marginTop: 1.5,
                    }}
                  >
                    {description}
                  </Typography>
                </Box>
              </Card>
            </Box>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default HighlightedCampaigns;
