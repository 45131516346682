import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import STORES from '../../../../constants/stores';
import { COMPANY_INFO } from '../../../../variables';

const DeliveryAtStore = ({ disabled, store, onStoreChange }) => {
  return (
    <FormControl
      margin='normal'
      sx={{ marginTop: 1.5 }}
    >
      <InputLabel>{`Armazém da ${COMPANY_INFO.name}`}</InputLabel>

      <Select
        disabled={disabled}
        value={disabled ? '' : store}
        label={`Armazém da ${COMPANY_INFO.name}`}
        onChange={onStoreChange}
        sx={{
          backgroundColor: '#fff',
        }}
      >
        {STORES.map(({ title }) => (
          <MenuItem
            key={title}
            value={title}
          >
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeliveryAtStore;
