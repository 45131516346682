import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBrands } from '../api';
import { MainContainer } from '../styles';
import BrandsCard from '../components/BrandsCard';
import Grid from '@mui/material/Unstable_Grid2';

const BrandsPage = () => {
  const [brands, setBrands] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getBrands()
      .then(({ brands }) => {
        setBrands(brands);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!brands) {
    return null;
  }

  return (
    <MainContainer>
      <Container>
        <Typography
          gutterBottom
          variant='h1'
          sx={{ textAlign: 'center' }}
        >
          Marcas
        </Typography>

        <Typography
          gutterBottom
          sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}
        >
          Aqui vai encontrar um conjunto de produtos das suas marcas favoritas.
        </Typography>

        <Box sx={{ paddingY: { xs: '20px', md: '30px' } }}>
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
          >
            {brands.map((brand) => (
              <Grid
                key={`brand-${brand._id}`}
                xs={6}
                sm={3}
                md={2}
              >
                <BrandsCard brand={brand} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </MainContainer>
  );
};

export default BrandsPage;
