import { LoadingButton } from '@mui/lab';
import { Box, IconButton, InputAdornment, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/login';
import { formatTextToTitleCase } from '../../constants/utils';
import {
  setAddresses,
  setCompanyInfo,
  setPersonalInfo,
  setRole,
  setSelectedClient,
  setSellerInfo,
  setUserId,
} from '../../state/userSlice';
import { StyledFormTextfield, StyledTextButton } from '../../styles';
import { ACCENT_COLOR } from '../../variables';
import { getCart, setCustomer } from '../../api/user';
import { setCart, setCartId, setCartTotal } from '../../state/cartSlice';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['userToken']);

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({
      ...values,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    if (!userData.email || !userData.password) {
      setLoading(false);

      return;
    }

    login(userData)
      .then(({ token, user, expiresIn }) => {
        const expiresInSeconds = parseInt(expiresIn.charAt(0)) * 24 * 60 * 60;
        const userRole = user.user_role.toLowerCase();
        let sellerCustomers = null;

        //  get user token
        setCookie('userToken', token, {
          path: '/',
          maxAge: expiresInSeconds,
        });
        dispatch(setCompanyInfo(user.companyInfo));
        dispatch(setPersonalInfo(user.personalInfo));
        dispatch(setAddresses(user.addresses));
        dispatch(setRole(userRole));
        dispatch(setUserId(user.id));

        const sellerInfo = {
          name: userRole === 'customer' ? user.sellerName : user.personalInfo.name,
          email: userRole === 'customer' ? user.sellerEmail : user.personalInfo.email,
          phone: userRole === 'customer' ? user.sellerPhone : user.personalInfo.phone,
          clients: user.customers,
        };

        userRole === 'customer' && (sellerInfo.paymentTime = user.payment_deadlines);
        dispatch(setSellerInfo(sellerInfo));

        //  if it is a seller
        if (userRole === 'seller') {
          const firstCustomer = user?.customers?.[0];
          dispatch(setSelectedClient(firstCustomer?.companyInfo?.name));

          //  set first customer as an active
          setCustomer({ customerId: firstCustomer?.id });

          if (user?.customers.length !== 0) {
            sellerCustomers = user?.customers;
          }
        }

        //  get cart items
        return userRole === 'customer' || (userRole === 'seller' && sellerCustomers !== null)
          ? getCart(userRole === 'customer' ? user?.cart_id : sellerCustomers?.[0]?.cart_id).then(
              ({ cart_items, total, _id }) => {
                dispatch(setCart(cart_items));
                dispatch(setCartId(_id));
                dispatch(setCartTotal(total));

                setLoading(false);

                navigate(-1, { replace: true });
              }
            )
          : navigate(-1, { replace: true });
      })
      .catch(({ response: { status, data } }) => {
        const { msg } = data;
        if (status >= 400) {
          setError(true);

          setErrorMessage(msg);
        }
        setLoading(false);
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <StyledFormTextfield
          fullWidth
          label='Introduza o seu email'
          type='email'
          name='email'
          required
          value={userData.email}
          onChange={handleChange}
        />
        <StyledFormTextfield
          fullWidth
          name='password'
          label='Introduza a sua palavra-passe'
          type={showPassword ? 'text' : 'password'}
          required
          value={userData.password}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {loading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton
            variant='contained'
            type='submit'
            title='Iniciar sessão'
          >
            {formatTextToTitleCase('iniciar sessão')}
          </StyledTextButton>
        )}
      </Box>

      <Snackbar
        autoHideDuration={6000}
        message={errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setError(false);
        }}
        ContentProps={{ sx: { backgroundColor: ACCENT_COLOR } }}
      />
    </>
  );
};

export default LoginForm;
