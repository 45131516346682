import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from '@mui/material';
import { StyledFormInputLabel, StyledFormTextfield } from '../../../../styles';
import DeliveryAtAddress from './DeliveryAtAddress';
import DeliveryAtStore from './DeliveryAtStore';
import { DELIVERY_METHODS } from '../OrderDetails';

const DetailsForm = ({
  shipping,
  onShippingChange,
  address,
  onStoreChange,
  store,
  deliveryOptions,
  onObservationsChange,
  paymentTime,
}) => {
  const isDeliveryAtAddress = shipping === DELIVERY_METHODS.ADDRESS;

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '80%', md: 'auto' },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          fontWeight: 'bold',
          marginBottom: 1,
        }}
      >
        Pagamento
      </Typography>

      <StyledFormTextfield
        label='Método De Pagamento'
        defaultValue='Transferência Bancária'
        InputProps={{
          disabled: true,
        }}
      />

      <StyledFormTextfield
        label='Pagamento Em'
        defaultValue={`${paymentTime} dias`}
        InputProps={{
          disabled: true,
        }}
      />

      <FormControl>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginBottom: 2,
          }}
        >
          <TextField
            id='outlined-multiline-static'
            label='Observações*'
            fullWidth
            multiline
            rows={3}
            inputProps={{ maxLength: 220 }}
            defaultValue=''
            onChange={onObservationsChange}
          />
          <Typography
            sx={{
              fontSize: '0.8rem',
            }}
          >
            *o campo está limitado a 220 caracteres
          </Typography>
        </Box>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: 'bold',
            }}
          >
            Envio
          </Typography>
          <Typography
            variant='p'
            sx={{ fontSize: '0.9em' }}
          >
            Poderá escolher apenas um método de envio.
          </Typography>
        </Box>

        <RadioGroup
          value={shipping}
          onChange={onShippingChange}
          name='shipping-options'
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <FormControlLabel
              value={DELIVERY_METHODS.ADDRESS}
              disabled={!deliveryOptions?.includes(DELIVERY_METHODS.ADDRESS)}
              control={<Radio />}
              label={
                <StyledFormInputLabel>Entrega em morada</StyledFormInputLabel>
              }
            />
            {isDeliveryAtAddress && <DeliveryAtAddress address={address} />}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 2,
            }}
          >
            <FormControlLabel
              value={DELIVERY_METHODS.STORE}
              disabled={!deliveryOptions?.includes(DELIVERY_METHODS.STORE)}
              control={<Radio />}
              label={
                <StyledFormInputLabel>
                  Levantamento em armazém
                </StyledFormInputLabel>
              }
            />
            {!isDeliveryAtAddress && (
              <DeliveryAtStore
                disabled={
                  !deliveryOptions?.includes(DELIVERY_METHODS.STORE) ||
                  isDeliveryAtAddress
                }
                store={store}
                onStoreChange={onStoreChange}
              />
            )}
          </Box>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default DetailsForm;
