import {
  Accordion,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  inputLabelClasses,
  outlinedInputClasses,
  Select,
  Tab,
  TableCell,
  tableCellClasses,
  Tabs,
  tabsClasses,
  TextField,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';

export let theme = createTheme();
theme = createTheme({
  typography: {
    fontFamily: 'Rubik',

    h1: {
      fontSize: '2em',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75em',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.5em',
      fontWeight: 700,
    },
    small: {
      fontSize: '0.9em',
    },
  },

  palette: {
    primary: {
      main: '#000',
      text: '#fff',
    },
    secondary: {
      main: '#fff',
    },
    secondaryColorButton: {
      main: '#fff',
      text: '#000',
    },
    error: {
      main: '#ff0000',
    },
    danger: {
      main: '#ff0000',
      text: '#ffffff',
      contrastText: '#ffffff',
      lowContrast: '#B90000',
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: ({ breakpoints }) => ({
        body: {
          [breakpoints.down(breakpoints.values.md)]: {
            fontSize: '14px',
          },
        },
        '.MuiAutocomplete-option': {
          fontSize: '0.9em',
        },
      }),
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});

const MainContainer = styled(Box, { label: 'MainContainer' })(() => ({
  paddingTop: '40px',
  paddingBottom: '40px',
}));

const StyledOrderSummaryBox = styled(Box)(() => ({
  margin: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledTextButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const StyledTextfield = styled(TextField)(() => ({
  backgroundColor: '#fff',
  borderRadius: '4px',
  marginTop: '20px',
}));

const StyledFormTextfield = styled(TextField)(() => ({
  variant: 'outlined',
  backgroundColor: '#fff',
}));

const StyledSearchTextfield = styled(TextField)(() => ({
  [`& .${outlinedInputClasses.notchedOutline},
  .${outlinedInputClasses.input},
  .${inputLabelClasses.outlined},
  &:hover .${inputLabelClasses.outlined},
  .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    border: 0,
    color: '#fff',
  },
}));

const StyledMyAccountTabs = styled(Tabs)(() => ({
  [`& .${tabsClasses.indicator}`]: {
    left: 0,
  },
}));

const StyledSidebarTab = styled(Tab)(() => ({
  textTransform: 'none',
  fontWeight: 700,
  marginRight: 'auto',
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '20px',
  paddingRight: '20px',
}));

const StyledCartTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    backgroundColor: '#ddd',
    textAlign: 'center',
    padding: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 700,
    fontSize: '12px',
    textAlign: 'center',
    backgroundColor: '#ddd',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '12px',
    textAlign: 'center',
  },
}));

const StyledTableSelect = styled(Select)(() => ({
  fontSize: '0.9em',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

const StyledFormInputLabel = styled(Typography)(() => ({
  fontWeight: 700,
}));

const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 700,
}));

const StyledAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: 0,
  paddingRight: 0,
}));

// template

const HeaderAccountButton = styled(Button)(() => ({
  textTransform: 'none',
  minWidth: 'initial',
}));

const HeaderAccountButtonText = styled(Typography)(() => ({
  display: { xs: 'none', md: 'flex' },
}));

const CategoryButton = styled(Button)(() => ({
  position: 'relative',
  textTransform: 'none',
  fontWeight: '700',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: '#fff',
  },
  '&.active::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FE0000',
  },
}));

//  PRODUCT LIST
const ProductsFilters = styled(Accordion)(() => ({
  boxShadow: 'none',
  [`&.Mui-expanded`]: {
    margin: 0,
  },
  [`& .MuiAccordionSummary-root.Mui-expanded`]: {
    minHeight: '48px',
  },
  [`& .MuiAccordionSummary-content.Mui-expanded`]: {
    margin: 0,
  },
  [`& .MuiAccordionDetails-root`]: {
    paddingTop: 16,
    [`.MuiFormGroup-root`]: {
      gap: '4px',
    },
  },
}));

const ClientSelectorSelect = styled(Autocomplete)(() => ({
  [`.MuiInputBase-root`]: {
    color: 'white',
    fontSize: '0.9em',
  },
}));

const CategoriesButton = styled(Box)(({ active }) => ({
  textDecoration: 'none',
  color: 'black',
  // color: `${active ? 'black' : '#909090'}`,
  fontWeight: 700,
  fontSize: '0.85em',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${active ? 'black' : 'transparent'}`,
  [`&:hover`]: {
    borderColor: 'black',
  },
}));

const SubCategoriesButton = styled(Box)(() => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'black',
  fontSize: '0.85em',
  [`&:hover`]: {
    color: theme.palette.danger.main,
  },
}));

export {
  StyledTextButton,
  StyledTextfield,
  StyledSidebarTab,
  StyledSearchTextfield,
  StyledTableCell,
  StyledCartTableCell,
  StyledTableSelect,
  StyledFormInputLabel,
  StyledOrderSummaryBox,
  StyledOrderSummaryText,
  StyledFormTextfield,
  StyledAccordion,
  StyledAccordionSummary,
  // template
  HeaderAccountButton,
  HeaderAccountButtonText,
  CategoryButton,
  MainContainer,
  StyledMyAccountTabs,
  //  reusable components
  ProductsFilters,
  ClientSelectorSelect,
  //  CATEGORIES MENU
  CategoriesButton,
  SubCategoriesButton,
};
