import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, InputAdornment, Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeLogin } from '../../../api/login';
import { StyledTextButton, StyledTextfield } from '../../../styles';
import { ACCENT_COLOR } from '../../../variables';

const AccessForm = () => {
  const navigate = useNavigate();
  const personalInfo = useSelector((state) => state.user.personalInfo);

  const [userData, setUserData] = useState({
    email: personalInfo.email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

  const [passwordValidationError, setPasswordValidationError] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);

  const validateInput = (e) => {
    let { name, value } = e.target;

    setPasswordValidationError((prev) => {
      const stateObj = { ...prev, [name]: '' };

      switch (name) {
        case 'oldPassword':
          if (!value) {
            stateObj[name] = 'Insira a palavra-passe atual.';
          }
          break;

        case 'newPassword':
          if (!value) {
            stateObj[name] = 'Insira a nova palavra-passe.';
          }
          break;

        case 'confirmPassword':
          if (!value) {
            stateObj[name] = 'Confirme a nova palavra-passe.';
          } else if (userData.newPassword && value !== userData.newPassword) {
            stateObj[name] = 'As palavras-passe não são iguais.';
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));

    validateInput(e);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    if (!userData.email || !userData.oldPassword || !userData.newPassword) {
      setLoading(false);
      return;
    }

    changeLogin(userData)
      .then(() => {
        setShowMessage(true);
        setFormSuccess(true);
      })
      .catch(({ response: { data, status } }) => {
        if (status === 401) {
          navigate('/login');
        }
        //  current password is incorrect
        if (status === 403) {
          setErrorMessage('A palavra-passe atual está incorreta');
        }
        //  new password isnt a valid value
        if (status === 422) {
          setErrorMessage(data?.errors?.[0]?.msg);
        }
        if (status >= 500) {
          setErrorMessage(data?.msg);
        }

        setFormSuccess(false);
        setShowMessage(true);
      })
      .then(() => {
        setLoading(false);
        setUserData({
          email: personalInfo.email,
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '100%', lg: '50%' },
        }}
      >
        <StyledTextfield
          fullWidth
          type='email'
          name='email'
          required
          label='Email'
          value={userData.email}
          disabled
        />

        <StyledTextfield
          fullWidth
          required
          name='oldPassword'
          label='Palavra-passe atual'
          type={showOldPassword ? 'text' : 'password'}
          value={userData.oldPassword}
          onChange={handleChange}
          onBlur={validateInput}
          error={passwordValidationError.oldPassword !== '' && true}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
                  {showOldPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {passwordValidationError.oldPassword !== '' && (
          <Typography
            variant='caption'
            color={ACCENT_COLOR}
          >
            {passwordValidationError.oldPassword}
          </Typography>
        )}
        <StyledTextfield
          fullWidth
          required
          name='newPassword'
          label='Nova palavra-passe'
          type={showNewPassword ? 'text' : 'password'}
          value={userData.newPassword}
          onChange={handleChange}
          onBlur={validateInput}
          error={passwordValidationError.newPassword !== '' && true}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {passwordValidationError.newPassword !== '' && (
          <Typography
            variant='caption'
            color={ACCENT_COLOR}
          >
            {passwordValidationError.newPassword}
          </Typography>
        )}
        <StyledTextfield
          fullWidth
          required
          name='confirmPassword'
          label='Confirmar nova palavra-passe'
          type={showConfirmationPassword ? 'text' : 'password'}
          value={userData.confirmPassword}
          onChange={handleChange}
          onBlur={validateInput}
          error={passwordValidationError.confirmPassword !== '' && true}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setShowConfirmationPassword(!showConfirmationPassword)}>
                  {showConfirmationPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {passwordValidationError.confirmPassword !== '' && (
          <Typography
            variant='caption'
            color={ACCENT_COLOR}
          >
            {passwordValidationError.confirmPassword}
          </Typography>
        )}

        <Typography sx={{ marginY: '10px', fontSize: '12px' }}>
          * Campos de preenchimento obrigatório.
        </Typography>

        {loading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton
            variant='contained'
            type={'submit'}
            title='Guardar Informações'
            disabled={
              passwordValidationError.oldPassword === '' &&
              passwordValidationError.newPassword === '' &&
              passwordValidationError.confirmPassword === '' &&
              userData.oldPassword !== '' &&
              userData.newPassword !== '' &&
              userData.confirmPassword !== ''
                ? false
                : true
            }
            sx={{ marginTop: '10px' }}
          >
            Guardar Informações
          </StyledTextButton>
        )}
      </Box>

      <Snackbar
        autoHideDuration={7500}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showMessage}
        onClose={(event, reason) => {
          if (reason === 'clickaway') return;
          if (!formSuccess) {
            setPasswordValidationError({
              oldPassword: null,
              newPassword: null,
              confirmPassword: null,
            });
          }
          setShowMessage(false);
        }}
      >
        {formSuccess ? (
          <Alert severity='success'>Palavra-passe alterada com sucesso</Alert>
        ) : (
          <Alert severity='error'>{errorMessage}</Alert>
        )}
      </Snackbar>
    </>
  );
};

export default AccessForm;
