import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import defaultImage from '../assets/default_image.png';
import { formatCurrency } from '../constants/utils';
import { imageOnError } from '../helpers';
import ProductTitleFormatter from './ProductTitleFormatter/ProductTitleFormatter';

const ProductCard = ({ product, showStockAvailability }) => {
  const user = useSelector((state) => state.user);
  const { role, pvprIsVisible } = user;
  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;

  const showPrice = {
    pvpr: userIsLogged,
    pvp: userIsLogged && ((role === 'customer' && pvprIsVisible) || role === 'seller'),
  };

  const images = product.images;
  const { title, brand, reference, pvpPrice, price, salePrice, onSale, stock } = product;
  const inStock = stock > 5;
  const outOfStock = stock <= 0;

  return (
    <Card
      square
      elevation={0}
      sx={{
        minWidth: '130px',
        maxWidth: '300px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingY: '15px',
        '&:hover': {
          '.productCardTitle': {
            color: 'danger.main',
          },
        },
      }}
      component={Link}
      to={`/produtos/${product.id || product._id}`}
      title={title}
    >
      <Box
        className='productCardImage'
        sx={{
          transition: 'transform 200ms ease',
        }}
      >
        <CardMedia
          component='img'
          image={images.length !== 0 ? images?.[0] : defaultImage}
          onError={imageOnError}
          alt={title}
          sx={{
            padding: { xs: '10px', md: '15px' },
            maxWidth: '100%',
            objectFit: 'contain',
            aspectRatio: '1',
          }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: '10px 0 0 0 !important',
        }}
      >
        <Box>
          <Typography sx={{ fontSize: '.9em', marginBottom: '8px' }}>{brand}</Typography>
          <Typography
            className='productCardTitle'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              transition: 'color 200ms ease',
            }}
            fontWeight={700}
          >
            <ProductTitleFormatter
              title={title}
              reference={reference}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: 1,
          }}
        >
          <Typography sx={{ fontSize: '.9em', marginTop: '4px' }}>{`Ref: ${reference}`}</Typography>

          {showStockAvailability && (
            <Typography
              variant='caption'
              sx={{
                fontWeight: 600,
                color: outOfStock ? 'danger.main' : inStock ? '#83C231' : '#FFC01D',
              }}
            >
              {outOfStock ? 'Esgotado' : inStock ? 'Disponível' : 'Stock reduzido'}
            </Typography>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {showPrice.pvpr && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.8em',
                  }}
                >
                  PVPR
                </Typography>
                <Typography
                  sx={{
                    fontSize: '0.875em',
                  }}
                >
                  {formatCurrency(pvpPrice)}
                </Typography>
              </Box>
            )}
            {showPrice.pvp && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.8em',
                  }}
                >
                  PREÇO
                </Typography>
                {!onSale ? (
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '0.875em',
                    }}
                  >
                    {formatCurrency(price)}
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 600,
                        textDecoration: 'line-through',
                        fontSize: '0.875em',
                      }}
                    >
                      {formatCurrency(price)}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: 'danger.main',
                        fontSize: '0.875em',
                      }}
                    >
                      {formatCurrency(salePrice)}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
