import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Cookies, CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import Login from './components/Login/Login';
import PasswordRecovery from './components/Login/PasswordRecovery';
import UserRegisterForm from './components/Login/UserRegisterForm';
import AccountAccess from './components/UserAccount/AccountAccess/AccountAccess';
// import AccountAddresses from './components/UserAccount/AccountAddresses/AccountAddresses';
import AccountInfo from './components/UserAccount/AccountInfo/AccountInfo';
import AccountPurchases from './components/UserAccount/AccountPurchases/AccountPurchases';
// import AccountUsers from './components/UserAccount/AccountUsers';
import InstitucionalPages from './components/InstitucionalPages/InstitucionalPages';
import ProductsHistory from './components/UserAccount/ProductsHistory';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AccountPage from './routes/AccountPage';
import BrandPage from './routes/BrandPage';
import BrandsPage from './routes/BrandsPage';
import CampaignDetails from './routes/CampaignDetails';
import Campaigns from './routes/Campaigns';
import CartPage from './routes/CartPage';
import ContactsPage from './routes/Contacts';
import HomePage from './routes/HomePage';
import LatestProducts from './routes/LatestProducts';
import LoginPage from './routes/LoginPage';
import NotFoundPage from './routes/NotFoundPage';
import ProductPage from './routes/ProductPage';
import ProductsPage from './routes/ProductsPage';
import SearchPage from './routes/SearchPage';
import store from './state/store';
import { theme } from './styles';
import { NOT_FOUND_ROUTE } from './variables';

const ProtectedRoute = ({ children }) => {
  const authentication = new Cookies().get('userToken');

  if (!authentication) {
    return (
      <Navigate
        to='/login'
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};

let persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route
                  path='login'
                  element={<LoginPage />}
                >
                  <Route
                    index
                    element={<Login />}
                  />
                  <Route
                    path='recuperar-palavra-passe'
                    element={<PasswordRecovery />}
                  />
                  <Route
                    path='registo-utilizador'
                    element={<UserRegisterForm />}
                  />
                </Route>
                <Route
                  path='/'
                  element={<App />}
                >
                  <Route
                    index
                    element={<HomePage />}
                  />
                  <Route
                    path='pesquisa'
                    element={<SearchPage />}
                  />
                  <Route
                    path='novidades'
                    element={<LatestProducts />}
                  />
                  <Route
                    path='campanhas'
                    element={<Campaigns />}
                  />
                  <Route
                    path='campanhas/:campaignSlug'
                    element={<CampaignDetails />}
                  />
                  <Route
                    path='marcas'
                    element={<BrandsPage />}
                  />
                  <Route
                    path='marcas/:brandSlug'
                    element={<BrandPage />}
                  />
                  <Route
                    path='carrinho'
                    element={<CartPage />}
                  />
                  <Route element={<ProtectedRoute />}>
                    <Route
                      path='conta'
                      element={<AccountPage />}
                    >
                      <Route
                        path='info'
                        element={<AccountInfo />}
                      />
                      <Route
                        path='acesso'
                        element={<AccountAccess />}
                      />
                      {/* <Route
                        path='moradas'
                        element={<AccountAddresses />}
                      /> */}
                      <Route
                        path='visualizacoes'
                        element={<ProductsHistory />}
                      />
                      <Route
                        path='encomendas'
                        element={
                          <AccountPurchases props={{ mode: 'orders' }} />
                        }
                      />
                      {/* <Route
                          path='utilizadores'
                          element={<AccountUsers />}
                        /> */}
                      <Route
                        path='conta-corrente'
                        element={
                          <AccountPurchases props={{ mode: 'receipts' }} />
                        }
                      />
                    </Route>
                  </Route>
                  {/* NOT FOUND ROUTES */}
                  {[`${NOT_FOUND_ROUTE}`, '*'].map((path, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={<NotFoundPage />}
                    />
                  ))}
                  {/* PRODUCTS LIST ROUTES */}
                  {[
                    'produtos',
                    ':categorySlug',
                    ':categorySlug/:subcategorySlug',
                    ':categorySlug/:subcategorySlug/:productTypesSlugs',
                  ].map((path, index) => (
                    <Route
                      path={path}
                      element={<ProductsPage />}
                      key={index}
                    />
                  ))}
                  <Route
                    path='produtos/:productSlug'
                    element={<ProductPage />}
                  />
                  <Route path='page'>
                    <Route
                      path={':slug'}
                      element={<InstitucionalPages />}
                    />
                    <Route
                      path={'contactos'}
                      element={<ContactsPage />}
                    />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
