import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductUserHistory } from '../../api/user';
import ProductCarousel from '../ProductCarousel';

const ProductsHistory = () => {
  const userRole = useSelector((state) => state.user.role);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const customerId = useSelector((state) => state.user.selectedClient.id || state.user.id);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductUserHistory(customerId).then(({ data }) => {
      setProducts(data.productHistory);
    });
  }, [customerId]);

  const breakpoints = [
    { width: 0, itemsToShow: 1 },
    { width: 350, itemsToShow: 2 },
    { width: 600, itemsToShow: 3 },
    { width: 800, itemsToShow: 4 },
  ];

  return (
    <Box
      sx={{
        flex: 3,
      }}
    >
      <Typography
        gutterBottom
        sx={{ fontWeight: 700, textTransform: 'uppercase' }}
      >
        Visualizações
      </Typography>

      {userRole === 'seller' && (
        <Typography>
          {`Consulte os últimos produtos visualizados de `} <strong>{selectedClient.companyInfo.name}</strong>.
        </Typography>
      )}

      {!products.length ? (
        <Typography sx={{ marginY: '20px' }}>Não existem produtos visualizados recentemente.</Typography>
      ) : (
        <ProductCarousel
          products={products}
          breakpoints={breakpoints}
        />
      )}
    </Box>
  );
};

export default ProductsHistory;
