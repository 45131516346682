import { Box, Typography } from '@mui/material';
import React from 'react';
// import BudgetRequestForm from '../components/BudgetRequestForm';
import StoreLocations from '../components/StoreLocations';
import STORES from '../constants/stores';
import { BG_PRIMARY_COLOR } from '../variables';

const ContactsPage = () => {
  return (
    <Box
      sx={{
        paddingY: 5,
        paddingX: { xs: 2, md: 5 },
        backgroundColor: BG_PRIMARY_COLOR,
      }}
    >
      <Typography
        gutterBottom
        variant='h4'
        sx={{ fontWeight: 700, textAlign: 'center' }}
      >
        Contactos
      </Typography>
      <StoreLocations stores={STORES} />
      {/* <BudgetRequestForm /> */}
    </Box>
  );
};

export default ContactsPage;
