import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapCategory, mapFilters, mapProduct } from './mapApiInterface';

//  GET
function getPage(name) {
  return axios.get(`${API_URL}/pages/${name}`, getHeaders()).then(({ data: { page } }) => page);
}

function getCategories() {
  return axios.get(`${API_URL}/categories`, getHeaders()).then(({ data }) =>
    data.categories.map((category) => {
      return mapCategory(category);
    })
  );
}

function getBrands(id) {
  return axios
    .get(id ? `${API_URL}/brands/${id}` : `${API_URL}/brands`, getHeaders())
    .then(({ data: brands }) => brands);
}

function getCampaigns() {
  return axios
    .get(`${API_URL}/campaigns`, getHeaders())
    .then(({ data: { campaigns } }) => campaigns);
}

function getCampaignDetail({ campaignSlug, productFilters = {}, sortBy = 'date:desc' }) {
  const productParams = { ...productFilters, sortBy };
  const productParamsString = new URLSearchParams(productParams).toString();

  return axios
    .get(`${API_URL}/campaigns/${campaignSlug}?${productParamsString}`, getHeaders())
    .then(({ data: { data } }) => {
      const mappedProducts = data.products.map((product) => mapProduct(product));
      const mappedFilters = mapFilters(data.filters);
      return { ...data, products: mappedProducts, filters: mappedFilters };
    });
}

export { getBrands, getCampaignDetail, getCampaigns, getCategories, getPage };
