import { Box, Card, CardContent, CardMedia, Divider, Snackbar, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../api/products';
import { formatCurrency } from '../../constants/utils';
import { imageOnError } from '../../helpers';
import { updateCart } from '../../state/cartSlice.js';
import { StyledTextButton } from '../../styles';
import { ACCENT_COLOR } from '../../variables';
import QuantitySelector from '../ProductPage/ProductDetails/QuantitySelector';
import ProductTitleFormatter from '../ProductTitleFormatter/ProductTitleFormatter';

const ProductListCard = ({ product, customerId }) => {
  const [quantity, setQuantity] = useState(1);
  const [productsForCart, setProductsForCart] = useState(new Map());
  const [addToCartConfirmation, setAddToCartConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const images = product.images;
  const brand = product.brand;
  const { id, title, description, reference, price, pvpPrice, onSale, salePrice, stock } = product;
  const inStock = stock > 5;
  const outOfStock = stock <= 0;

  const user = useSelector((state) => state.user);
  const { role, pvprIsVisible } = user;

  const [cookies] = useCookies(['userToken']);
  const userIsLogged = !!cookies.userToken;

  const showPrice = {
    pvpr: userIsLogged,
    pvp: userIsLogged && ((role === 'customer' && pvprIsVisible) || role === 'seller'),
  };

  const handleProductSelect = useCallback(
    (product) => {
      const newProductsForCart = new Map(productsForCart);

      newProductsForCart.set(product.reference, product);
      setProductsForCart(newProductsForCart);
    },
    [setProductsForCart, productsForCart]
  );

  function addToCart() {
    const products = Array.from(productsForCart.values());
    const newProducts = products.filter(({ quantity }) => quantity > 0);

    addProductsToCart(newProducts, customerId)
      .then(({ cart_items }) => {
        dispatch(updateCart(cart_items));
        setAddToCartConfirmation(true);
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setErrorMessage(msg);
            console.error(`Error while adding product to cart at ProductListCard: ${msg}`);
          }
        }
      );

    setAddToCartConfirmation(false);
  }

  useEffect(() => {
    product.quantity = quantity;
    handleProductSelect(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PriceBlock = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showPrice.pvpr && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '0.8em',
              }}
            >
              PVPR
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875em',
              }}
            >
              {formatCurrency(pvpPrice)}
            </Typography>
          </Box>
        )}

        {showPrice.pvp && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: '0.8em',
              }}
            >
              PREÇO
            </Typography>

            {!onSale ? (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '0.875em',
                }}
              >
                {formatCurrency(price)}
              </Typography>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    textDecoration: 'line-through',
                    fontSize: '0.875em',
                  }}
                >
                  {formatCurrency(price)}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: 'danger.main',
                    fontSize: '0.875em',
                  }}
                >
                  {formatCurrency(salePrice)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Card
      raised
      sx={{
        marginY: '20px',
        display: 'flex',
        alignItems: 'flex-start',
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
      }}
    >
      <Box
        title={title}
        component={Link}
        to={`/produtos/${id}`}
        sx={{
          maxWidth: '100%',
          padding: { xs: '10px', sm: '15px', lg: '20px' },
          display: { xs: 'none', sm: 'initial' },
          flex: 1,
        }}
      >
        <CardMedia
          component='img'
          image={images[0]}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 2.5,
          padding: '20px !important',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <Typography sx={{ fontSize: '.9em' }}>{brand}</Typography>

            <Typography sx={{ fontSize: '.9em' }}>{`Ref: ${reference}`}</Typography>
          </Box>

          <Typography
            sx={{
              display: 'flex',
              color: 'black',
              fontWeight: 700,
              textDecoration: 'none',
              transition: 'color 200ms ease',
              '&:hover': {
                color: 'danger.main',
              },
            }}
            marginBottom={1}
            title={title}
            component={Link}
            to={`/produtos/${id}`}
          >
            <ProductTitleFormatter
              title={title}
              reference={reference}
            />
          </Typography>

          <Typography
            gutterBottom
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              lineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </Typography>

          <Typography
            sx={{
              marginTop: '16px',
              fontSize: '12px',
              fontWeight: 600,
              color: outOfStock ? 'danger.main' : inStock ? '#83C231' : '#FFC01D',
            }}
          >
            {outOfStock ? 'Esgotado' : inStock ? 'Disponível' : 'Stock reduzido'}
          </Typography>
        </Box>

        {(showPrice.pvp || showPrice.pvpr) && (
          <>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{ marginY: 2 }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'flex-start', md: 'center' },
                gap: { xs: 2, md: 0 },
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  gap: 3,
                  minWidth: '275px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 600,
                    }}
                  >
                    Preço
                  </Typography>
                  <PriceBlock />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 600,
                    }}
                  >
                    Quantidade
                  </Typography>
                  <Box sx={{ marginLeft: '-8px' }}>
                    <QuantitySelector
                      product={product}
                      quantity={{ value: quantity, setQuantity }}
                      selectForCart={handleProductSelect}
                    />
                  </Box>
                </Box>
              </Box>

              <Box>
                <StyledTextButton
                  variant='contained'
                  fullWidth
                  sx={{}}
                  onClick={addToCart}
                  title='Adicionar ao Carrinho'
                >
                  Adicionar ao Carrinho
                </StyledTextButton>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
      {addToCartConfirmation && (
        <Snackbar
          autoHideDuration={4000}
          message={addToCartConfirmation ? 'Adicionado ao carrinho' : errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={addToCartConfirmation}
          ContentProps={{
            sx: {
              backgroundColor: addToCartConfirmation ? '#4FD290' : ACCENT_COLOR,
            },
          }}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setAddToCartConfirmation(false);
          }}
        />
      )}
    </Card>
  );
};

export default ProductListCard;
