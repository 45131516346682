import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { SMALL_DEVICES_BREAKPOINT, PRODUCT_INFO_TABS } from '../../../variables';
import ProductDetailsTable from './TechTable';
import VideoCard from './VideoCard';

const ProductTabs = ({ video, params, datasheet, SMALL_DEVICES }) => {
  const XSMALL_DEVICES = useMediaQuery(`(max-width:${SMALL_DEVICES_BREAKPOINT})`);

  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box marginTop={6}>
      <Tabs
        value={activeTab}
        textColor='secondary'
        variant={XSMALL_DEVICES && PRODUCT_INFO_TABS.length > 2 ? 'scrollable' : null}
        scrollButtons={XSMALL_DEVICES && PRODUCT_INFO_TABS.length > 2 && true}
        allowScrollButtonsMobile
      >
        {PRODUCT_INFO_TABS.map((tab, index) => (
          <Tab
            key={`product-info-tab-${tab}`}
            value={index}
            label={tab}
            onClick={() => setActiveTab(index)}
            sx={{
              textTransform: 'none',
              marginRight: '8px',
              backgroundColor: activeTab === index ? 'danger.main' : '#F6F8FB',
              fontWeight: 600,
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
            }}
          />
        ))}
      </Tabs>

      {activeTab === 0 && <ProductDetailsTable params={params} />}

      {activeTab === 1 && (
        <VideoCard
          video={video}
          SMALL_DEVICES={SMALL_DEVICES}
        />
      )}

      {/* {activeTab === 2 && <Downloads datasheet={datasheet} />} */}
    </Box>
  );
};

export default ProductTabs;
