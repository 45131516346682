import { Box, FormControl, TextField } from '@mui/material';

const DeliveryAtAddress = ({ address }) => {
  const { street, zipCode, city, country } = address;

  return (
    <>
      <FormControl>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <TextField
            id='outlined-multiline-static'
            label='Morada'
            multiline
            disabled
            value={`${street}\n${zipCode} ${city}\n${country}`}
            sx={{ flex: 1, marginTop: '20px', lineHeight: 1.5 }}
          ></TextField>
        </Box>
      </FormControl>
    </>
  );
};

export default DeliveryAtAddress;
