import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { StyledMyAccountTabs, StyledSidebarTab } from '../../styles';

const UserAccountNavBar = ({ SMALL_DEVICES }) => {
  const userRole = useSelector((state) => state.user.role);

  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split('/');

  const currentLocation = splitLocation[splitLocation.length - 1];

  const ACCOUNT_NAVBAR = [
    { title: 'Os Meus Dados', url: 'info' },
    userRole !== 'seller' && { title: 'Dados de Acesso', url: 'acesso' },
    // userRole !== 'seller' && { title: 'Livro de Endereços', url: 'moradas' },
    { title: 'Encomendas', url: 'encomendas' },
    { title: 'Conta Corrente', url: 'conta-corrente' },
    { title: 'Visualizações', url: 'visualizacoes' },
  ].filter(Boolean);

  return (
    <Box sx={{ marginBottom: { xs: '30px', md: 0 } }}>
      <StyledMyAccountTabs
        orientation={SMALL_DEVICES ? 'horizontal' : 'vertical'}
        variant={SMALL_DEVICES ? 'scrollable' : 'standard'}
        value={currentLocation}
        scrollButtons
        allowScrollButtonsMobile
      >
        {ACCOUNT_NAVBAR.map(({ title, url }) => (
          <StyledSidebarTab
            key={url}
            value={url}
            label={title}
            component={Link}
            to={url}
            sx={{
              alignItems: SMALL_DEVICES ? 'center' : 'start',
            }}
          />
        ))}
      </StyledMyAccountTabs>
    </Box>
  );
};

export default UserAccountNavBar;
