import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { CssBaseline } from '@mui/material';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <CssBaseline>
      <div className='app'>
        <Header />

        <Outlet />

        <Footer />
      </div>
    </CssBaseline>
  );
};

export default App;
