import { Box } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/companyLogo.svg';
import loginImage from '../assets/loginImage.svg';
import { BG_PRIMARY_COLOR } from '../variables';

const LoginPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: BG_PRIMARY_COLOR,
        overflowY: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box
        component='img'
        src={loginImage}
        alt='Imporaudio'
        sx={{
          height: { xs: '150px', sm: '100%' },
          width: { xs: '100%', sm: '50%' },
          objectFit: 'cover',
          marginBottom: { xs: '6px', sm: '0px' },
        }}
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component={Link}
          to='/'
          sx={{ maxWidth: { xs: '275px', md: '100%' }, marginTop: { xs: '20px', md: 0 }, textAlign: 'center' }}
        >
          <Logo width='80%' />
        </Box>

        <Outlet />
      </Box>
    </Box>
  );
};

export default LoginPage;
