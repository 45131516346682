import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';
import store1_location from '../assets/store1_location.png';

const StoreLocations = ({ stores }) => {
  return (
    <Box
      sx={{
        marginTop: { xs: 5, md: 6 },
        display: 'flex',
        gap: 1,
        justifyContent: 'center',
      }}
    >
      {stores.map(({ title, address, phone, mobile, fax, email }) => {
        const { street, zipCode, city, country } = address;

        return (
          <Box
            key={`store-location-${title}`}
            sx={{ display: 'flex' }}
          >
            <Box sx={{ flex: 1, textAlign: 'start' }}>
              <Typography
                fontSize='1.3rem'
                fontWeight={700}
                gutterBottom
              >
                {title}
              </Typography>

              <Typography gutterBottom>
                {street} <br /> {zipCode} {city} <br />
                {country}
              </Typography>

              {phone && (
                <>
                  <Typography gutterBottom>
                    {`Telefone: ${phone}`} <br />
                    <Typography variant='caption'>
                      {`(Chamada para a rede fixa nacional)`}
                    </Typography>
                  </Typography>
                </>
              )}

              {mobile && <Typography>{`Telemóvel: ${mobile}`}</Typography>}

              {fax && <Typography>{`Fax: ${fax}`}</Typography>}

              <br />

              {email && <Typography gutterBottom>{`Email: ${email}`}</Typography>}

              <Typography gutterBottom>
                Horário de funcionamento: dias úteis (9h - 12:30h / 14h - 18:30h)
              </Typography>

              <Link
                href={`https://maps.google.com/?q=imporaudio`}
                target='_blank'
                rel='noreferrer'
                underline='none'
                fontWeight={700}
              >
                Obter direções
                <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                  <FiChevronRight />
                </IconContext.Provider>
              </Link>
            </Box>

            <Link
              href={`https://maps.google.com/?q=imporaudio`}
              target='_blank'
              rel='noreferrer'
              sx={{ flex: 1, display: { xs: 'none', sm: 'initial' } }}
            >
              <img
                src={store1_location}
                width='100%'
                alt=''
              />
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default StoreLocations;
