import {
  Box,
  Container,
  Divider,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCart, setCustomer } from '../../api/user';
import {
  setCart,
  setCartId,
  setCartTotal,
  setDiscount,
} from '../../state/cartSlice';
import { setSelectedClient } from '../../state/userSlice';
import { ClientSelectorSelect } from '../../styles';
import { ACCENT_COLOR } from '../../variables';

const ClientSelector = () => {
  const [messageIsVisible, setMessageIsVisible] = useState(false);
  const sellerInfo = useSelector((state) => state.user.sellerInfo);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const { clients } = sellerInfo || [];
  const { cart_id, id: selectedClientId } = selectedClient;
  const [clientId, setClientId] = useState(selectedClientId);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    selectedClientId !== clientId &&
      setCustomer({ customerId: selectedClientId })
        .then(() => {
          setClientId(selectedClientId);

          return getCart(cart_id)
            .then(({ cart_items: cart, _id: id, total, total_discount }) => {
              dispatch(setCart(cart));
              dispatch(setCartId(id));
              dispatch(setDiscount(total_discount));
              dispatch(setCartTotal(total));

              //  navigate to 'homepage'
              navigate('/');
            })
            .catch(({ response: { status } }) => {
              if (status === 401) navigate('/login');
            });
        })
        .catch(({ response: { status } }) => {
          if (status === 422) setMessageIsVisible(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, cart_id, dispatch]);

  return (
    <>
      <Box
        paddingY={1.5}
        sx={{
          backgroundColor: '#6f6f6f90',
        }}
      >
        <Container>
          <Box
            sx={{
              display: 'flex',
              gap: { xs: 0.5, md: 2 },
              alignItems: 'center',
              justifyContent: 'end',
              flexDirection: { xs: 'column', md: 'row' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography
                variant='small'
                color='secondary'
              >
                O seu perfil:
              </Typography>
              <Typography
                variant='small'
                color='secondary'
                sx={{ fontWeight: 600 }}
              >
                Vendedor
              </Typography>
            </Box>

            <Divider
              orientation='vertical'
              flexItem
              sx={{ borderColor: 'white' }}
            />

            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography
                color='secondary'
                variant='small'
              >
                O seu cliente:
              </Typography>

              <ClientSelectorSelect
                disablePortal
                id='client-selection'
                options={clients}
                disableClearable={true}
                getOptionLabel={(option) => option?.companyInfo?.name}
                onChange={(event, newValue) =>
                  dispatch(setSelectedClient(newValue?.companyInfo?.name))
                }
                isOptionEqualToValue={(option, value) =>
                  option?.companyInfo?.name === value?.companyInfo?.name
                }
                defaultValue={selectedClient}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={params?.companyInfo?.name}
                    value={params?.companyInfo?.name}
                  />
                )}
                renderOption={(props, option) => (
                  <Box
                    {...props}
                    component='li'
                    key={`clientOption-${option.id}`}
                    sx={{
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '2px',
                        height: '100%',
                        backgroundColor: '#FE0000',
                        opacity: option.hasProductsOnCart ? '1' : '0',
                      },
                    }}
                  >
                    {option?.companyInfo?.name}
                  </Box>
                )}
                size='small'
                sx={{ width: 250 }}
              />
            </Box>
          </Box>
        </Container>
      </Box>

      <Snackbar
        autoHideDuration={6000}
        message='Houve um problema na alteração do cliente'
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={messageIsVisible}
        onClose={(reason) => {
          if (reason === 'clickaway') return;
          setMessageIsVisible(false);
        }}
        ContentProps={{
          sx: {
            backgroundColor: ACCENT_COLOR,
          },
        }}
      />
    </>
  );
};

export default ClientSelector;
