import { Box, Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import { kebabCase } from 'lodash';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const Breadcrumbs = ({ productDetail, categories }) => {
  const { categorySlug, subcategorySlug, productTypesSlugs } = useParams();
  let arrayParams = [];

  if (productDetail) {
    //  product detail
    arrayParams = categories;
  } else {
    //  product list
    const getBreadcrumbName = (name, slug) => {
      return categories
        .find((category) => category.id === name)
        ?.options?.find((option) => kebabCase(slug) === kebabCase(option));
    };

    if (categorySlug) {
      arrayParams.push({
        name: getBreadcrumbName('category', categorySlug),
        slug: `/${categorySlug}`,
      });
    }
    if (subcategorySlug) {
      arrayParams.push({
        name: getBreadcrumbName('subcategories', subcategorySlug),
        slug: `/${categorySlug}/${subcategorySlug}`,
      });
    }
    if (productTypesSlugs) {
      arrayParams.push({
        name: getBreadcrumbName('productTypes', productTypesSlugs),
        slug: `/${categorySlug}/${subcategorySlug}/${productTypesSlugs}`,
      });
    }
  }

  return (
    <MuiBreadcrumbs aria-label='breadcrumb'>
      {arrayParams.map((param, index) => {
        const isLastParam = arrayParams.length - 1 === index;
        return (
          <Box
            key={`breadcrumb-item-${param}`}
            component={!isLastParam || productDetail ? Link : 'span'}
            to={!isLastParam || productDetail ? param.slug : null}
            title={param.name}
            sx={{
              fontSize: { xs: '0.8em', md: '0.85em' },
              color: 'black',
              textDecoration:
                !isLastParam || productDetail ? 'underline' : 'none',
            }}
          >
            {param.name}
          </Box>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
