import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { PAGES_SLUGS } from '../../variables';

const CustomerSupport = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h5'
        gutterBottom
        sx={{ fontSize: '1.1em' }}
      >
        Apoio ao Cliente
      </Typography>

      <Typography
        marginBottom={1}
        component={RouterLink}
        to={PAGES_SLUGS.termsAndConditions}
        title='Termos e Condições'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Termos e Condições
      </Typography>

      <Typography
        marginBottom={1}
        component={RouterLink}
        to={PAGES_SLUGS.privacyPolicy}
        title='Política de Privacidade'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Política de Privacidade
      </Typography>

      <Link
        marginBottom={1}
        href='https://www.livroreclamacoes.pt/Inicio/'
        target='_blank'
        rel='noreferrer'
        title='Livro de Reclamações'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Livro de Reclamações
      </Link>
    </Grid>
  );
};

export default CustomerSupport;
