import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useParams, useSearchParams } from 'react-router-dom';
import { slugify } from '../helpers';
import { ProductsFilters } from '../styles';

const ProductFilter = ({ filter, activeFilters }) => {
  const { id, title, options } = filter;
  const { productTypesSlugs } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOptions = activeFilters[id] || [];

  const getActiveFilters = Boolean(
    Object.keys(activeFilters).find((filterName) => filterName === id)
  );

  function handleFilterChange(checked, optionSelected) {
    if (checked) {
      const paramAlreadyExists = searchParams.has(id);
      if (paramAlreadyExists) {
        const oldValue = searchParams.get(id);
        searchParams.set(id, [oldValue].concat(optionSelected));
      } else {
        searchParams.set(id, optionSelected);
      }
    } else {
      const oldValue = searchParams.get(id);
      const oldValueArray = oldValue.split(',');
      const oldValueFiltered = oldValueArray.filter(
        (e) => e !== optionSelected
      );
      if (oldValueFiltered.length > 0) {
        searchParams.set(id, oldValueFiltered);
      } else {
        searchParams.delete(id);
      }
    }
    setSearchParams(searchParams);
  }

  return (
    <ProductsFilters defaultExpanded={getActiveFilters}>
      <AccordionSummary
        expandIcon={
          <FiChevronDown
            size={20}
            color='#000'
          />
        }
      >
        <Typography
          sx={{
            fontSize: '0.9rem',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails>
        <FormGroup>
          {options.map((option, index) => {
            const optionName =
              id === 'category' ||
              id === 'subcategories' ||
              id === 'productTypes'
                ? slugify(option)
                : option;

            return productTypesSlugs &&
              productTypesSlugs === optionName ? null : (
              <FormControlLabel
                key={`filter-${optionName}-${index}`}
                label={option}
                title={option}
                control={
                  <Checkbox
                    checked={activeOptions.includes(optionName)}
                    onChange={(e) =>
                      handleFilterChange(e.target.checked, optionName)
                    }
                  />
                }
                sx={{
                  marginRight: 0,
                  '.MuiCheckbox-root': {
                    paddingTop: 0.75,
                    paddingBottom: 0.75,
                  },
                  '.MuiFormControlLabel-label': {
                    fontSize: '0.9rem',
                    lineHeight: '1',
                  },
                }}
              />
            );
          })}
        </FormGroup>
      </AccordionDetails>
    </ProductsFilters>
  );
};

export default React.memo(ProductFilter);
