import { Box, Typography } from '@mui/material';
import ProductTitleFormatter from '../../ProductTitleFormatter/ProductTitleFormatter';
import ProductCheckout from './ProductCheckout';

const ProductDetails = ({ details, SMALL_DEVICES, showPriceToUser, customerId, userRole }) => {
  const { title, brand, description, reference, stock, deliveryDate, deliveryQuantity } = details;
  const inStock = stock > 5;

  const outOfStock = stock <= 0;

  const loginButton = `<a 
    href='/login'
    title='Login'
    style="color:#ff0000; font-weight:600"
  >Login</a>
`;

  const loginText = `Por favor, faça ${loginButton} para visualizar os preços dos produtos.
`;

  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <Typography
        marginBottom={1}
        sx={{ fontSize: '.9em' }}
      >
        {brand}
      </Typography>

      <Typography variant='h1'>
        <ProductTitleFormatter
          title={title}
          reference={reference}
        />
      </Typography>

      <Typography
        marginTop={1}
        sx={{ fontSize: '.9em' }}
      >{`Ref: ${reference}`}</Typography>

      <Typography
        marginTop={2}
        sx={{
          fontSize: '12px',
          fontWeight: 600,
          color: outOfStock ? 'danger.main' : inStock ? '#83C231' : '#FFC01D',
        }}
      >
        {`${outOfStock ? 'Esgotado' : inStock ? 'Disponível' : 'Stock reduzido'} ${
          userRole === 'seller' && !outOfStock ? ` - ${stock} unidade(s)` : ''
        }`}
      </Typography>

      {userRole === 'seller' && !!deliveryDate && (
        <Typography
          sx={{
            fontSize: '0.85rem',
            fontWeight: 500,
            marginTop: 0.5,
            textDecoration: 'underline',
          }}
        >
          Data de chegada prevista: {deliveryDate}
          {!!deliveryQuantity ? ` - ${deliveryQuantity} unidade(s)` : ''}
        </Typography>
      )}

      <Typography
        marginTop={2}
        sx={{ whiteSpace: 'pre-line' }}
      >
        {description}
      </Typography>

      <Box marginTop={3}>
        {showPriceToUser ? (
          <ProductCheckout
            product={details}
            SMALL_DEVICES={SMALL_DEVICES}
            customerId={customerId}
          />
        ) : (
          <Typography dangerouslySetInnerHTML={{ __html: loginText }} />
        )}
      </Box>
    </Box>
  );
};

export default ProductDetails;
