import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StyledTextButton } from '../../../styles';
import { QuantitySelectorInput } from './SummaryTable/SummaryQuantity';

const ProductOfferModal = ({
  productOfferModal,
  setProductOfferModal,
  addProductOffer,
  product,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [temporaryQuantity, setTemporaryQuantity] = useState(quantity);

  function decrement() {
    if (quantity <= 1) return;
    setQuantity(quantity - 1);
  }

  function increment() {
    if (quantity >= product.quantity) return;
    setQuantity(quantity + 1);
  }

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3) return;

    let newQuantityNumbered = Number(e);

    //  if number entered is greater than the quantity available
    if (newQuantityNumbered >= product.quantity) newQuantityNumbered = product.quantity;
    setTemporaryQuantity(newQuantityNumbered);

    //  IF number of caracteres is equal 0 » return
    if (e.length === 0) return;

    setQuantity(newQuantityNumbered);
  };

  useEffect(() => {
    setTemporaryQuantity(quantity);
  }, [quantity]);

  return (
    <Dialog
      open={productOfferModal}
      onClose={() => setProductOfferModal(false)}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', md: '500px' },
          width: '100%',
          margin: { xs: 2, md: 4 },
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, paddingY: 3 }}>Oferecer produto</DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography>Indique a quantidade a oferecer</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Button
              sx={{ minWidth: '30px' }}
              onClick={decrement}
              disabled={quantity === 1}
            >
              -
            </Button>

            <QuantitySelectorInput
              id='discountPercentage-selector'
              required
              value={temporaryQuantity}
              onChange={(e) =>
                e.target.value !== temporaryQuantity ? openFieldChange(e.target.value) : null
              }
              onBlur={({ target: { value } }) =>
                Number(value) === 0 && setTemporaryQuantity(quantity)
              }
            />

            <Button
              sx={{ minWidth: '30px' }}
              onClick={increment}
              disabled={quantity >= product.quantity}
            >
              +
            </Button>
          </Box>
        </Box>
        <DialogActions
          sx={{
            padding: 0,
            marginTop: 3,
          }}
        >
          <StyledTextButton
            title='title'
            variant='contained'
            color='secondaryColorButton'
            onClick={() => setProductOfferModal(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            title='Aplicar oferta'
            variant='contained'
            onClick={() => {
              setProductOfferModal(false);
              addProductOffer({
                ...product,
                reference: `${product.reference}_offer`,
                quantity: quantity,
                discount: 100,
              });
            }}
          >
            Aplicar oferta
          </StyledTextButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ProductOfferModal;
