import { Box, Typography } from '@mui/material';
import ProductTitleFormatter from '../../../ProductTitleFormatter/ProductTitleFormatter';
import { imageOnError } from '../../../../helpers';
import { Link } from 'react-router-dom';

const SummaryProductInfo = ({ id, images, title, reference, brand, offerProduct }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          marginRight: '20px',
          display: ' flex',
          alignItems: ' center',
          justifyContent: 'center',
        }}
        component={Link}
        to={`/produtos/${id}`}
        title={title}
      >
        <Box
          component='img'
          src={images[0]}
          onError={imageOnError}
          width='100px'
          alt={title}
          sx={{ objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <Box
        sx={{
          textAlign: 'left',
        }}
      >
        <Typography
          sx={{
            textDecoration: 'none',
          }}
          color='black'
          fontWeight={700}
          component={Link}
          to={`/produtos/${id}`}
          title={title}
        >
          <ProductTitleFormatter
            title={title}
            reference={reference}
          />
        </Typography>

        <Typography
          sx={{
            fontSize: '0.85rem',
          }}
        >{`Ref: ${reference}`}</Typography>
        <Typography
          sx={{
            fontSize: '0.85rem',
          }}
        >{`Marca: ${brand}`}</Typography>
        {offerProduct && (
          <Typography
            sx={{
              color: 'danger.main',
              fontWeight: 600,
            }}
          >
            OFERTA
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SummaryProductInfo;
