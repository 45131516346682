import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkDeliveryCost, confirmOrder } from '../../../api/checkout';
import { StyledTextButton } from '../../../styles';
import { ACCENT_COLOR } from '../../../variables';
import DetailsForm from './DetailsForm/DetailsForm';
import OrderSummaryCard from './OrderSummaryCard';

export const DELIVERY_METHODS = {
  STORE: 'deliveryAtStore',
  ADDRESS: 'deliveryAtAddress',
};

const OrderDetails = ({ setActiveStep, cart: cartObject }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [shipping, setShipping] = useState(DELIVERY_METHODS.ADDRESS);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryOptions] = useState(['deliveryAtAddress', 'deliveryAtStore']);
  const [observations, setObservations] = useState('');
  const [store, setStore] = useState('');
  const { id: cartId, cart: cartItems, total: totalCart, discount: totalDiscount } = cartObject;
  const cartTaxes = (totalCart + deliveryCost) * 0.23;
  const { user } = useSelector((state) => state);
  const role = useSelector((state) => state.user.role);
  const { selectedClient, sellerInfo } = user;
  
  //	validate type of client
  const clientAddresses = role === 'customer' ? user.companyInfo : selectedClient.companyInfo;
  const clientPaymentTime = role === 'customer' ? sellerInfo.paymentTime : selectedClient.payment_deadlines;
  const [address] = useState(clientAddresses);
  const userId = selectedClient.id;

  const [orderDetails, setOrderDetails] = useState({
    paymentType: 'Transferência Bancária',
    paymentTime: clientPaymentTime,
    shipping,
    deliveryAtAddress: address?.street,
    deliveryAtStore: store,
    notes: observations,
    total: 0,
    total_discount: totalDiscount,
    shipping_price: deliveryCost,
    cart: cartItems,
    cart_id: cartId,
  });

  useEffect(() => {
    const cartTotal = totalCart + cartTaxes + deliveryCost;
    setOrderDetails((values) => ({
      ...values,
      total: cartTotal,
    }));
  }, [totalCart, cartTaxes, deliveryCost]);

  useEffect(() => {
    if (shipping === DELIVERY_METHODS.STORE) {
      setDeliveryCost(0);
      setOrderDetails((values) => ({
        ...values,
        shipping_price: 0,
      }));
      return;
    }

    if (!clientAddresses.zipCode) {
      return;
    }

    checkDeliveryCost(clientAddresses.zipCode, userId)
      .then(({ deliveryCost }) => {
        setDeliveryCost(deliveryCost);
        setOrderDetails((values) => ({
          ...values,
          shipping_price: deliveryCost,
        }));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [clientAddresses, shipping, navigate, userId]);

  function onShippingChange(e) {
    setShipping(e.target.value);
    setOrderDetails((values) => ({ ...values, shipping: e.target.value }));
  }

  function onStoreChange(e) {
    setStore(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      deliveryAtStore: e.target.value,
    }));
  }

  function onObservationsChange(e) {
    setObservations(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      notes: e.target.value,
    }));
  }

  function handleConfirmOrder() {
    setLoading(true);
    confirmOrder(orderDetails)
      .then(() => {
        setLoading(false);
        setActiveStep(2);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setLoading(false);
            setError(true);
            setErrorMessage(msg);
          }
        }
      );
  }

  return (
    <Box>
      <Typography
        variant='h5'
        sx={{ marginTop: { xs: '40px', md: '60px' }, marginBottom: { xs: '30px', md: '40px' }, textAlign: 'center' }}
      >
        Informações da encomenda
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' },
          alignItems: { sm: 'center', md: 'initial' },
          gap: { xs: 5, md: 6 },
        }}
      >
        <DetailsForm
          onObservationsChange={onObservationsChange}
          shipping={shipping}
          onShippingChange={onShippingChange}
          address={address}
          onStoreChange={onStoreChange}
          store={store}
          deliveryOptions={deliveryOptions}
          paymentTime={orderDetails.paymentTime}
        />

        <OrderSummaryCard
          cart={cartObject}
          total={orderDetails.total}
          cartTaxes={cartTaxes}
          deliveryCost={deliveryCost}
        />
      </Box>

      <Box
        sx={{
          marginY: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondaryColorButton'
          onClick={() => setActiveStep(0)}
          sx={{ marginLeft: 'auto' }}
        >
          Voltar atrás
        </StyledTextButton>

        {loading ? (
          <LoadingButton loading />
        ) : (
          <StyledTextButton
            disabled={
              (shipping === DELIVERY_METHODS.STORE && store) || (shipping === DELIVERY_METHODS.ADDRESS && address)
                ? false
                : true
            }
            variant='contained'
            onClick={handleConfirmOrder}
            sx={{ marginLeft: '10px' }}
          >
            Finalizar encomenda
          </StyledTextButton>
        )}

        <Snackbar
          autoHideDuration={6000}
          message={errorMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={error}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setError(false);
          }}
          ContentProps={{ sx: { backgroundColor: ACCENT_COLOR } }}
        />
      </Box>
    </Box>
  );
};

export default OrderDetails;
