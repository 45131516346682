import { Box, Button, Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useRef, useState } from 'react';
import { BiMenu } from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { getCategories } from '../../../api';
import { setCategories } from '../../../state/categoriesSlice';
import { CategoryButton } from '../../../styles';
import { NAVBAR_LINKS, SMALL_DEVICES_BREAKPOINT } from '../../../variables';
import MenuMobile from '../MenuMobile';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';

const NavBar = () => {
  const XSMALL_DEVICES = useMediaQuery(
    `(max-width:${SMALL_DEVICES_BREAKPOINT})`
  );

  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const categoriesMenuRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
        dispatch(setCategories(newCategories));
        setCategoriesTree(newCategories);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box
      ref={navRef}
      sx={{
        position: 'relative',
        backgroundColor: '#F5F5F8',
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box component='nav'>
              {XSMALL_DEVICES ? (
                <MenuMobile />
              ) : (
                <Box
                  display='flex'
                  sx={{
                    width: '100%',
                  }}
                >
                  {NAVBAR_LINKS.map(({ title, url }) => {
                    const productsButton = title === 'Produtos';
                    const contactsButton = title === 'Contactos';

                    return productsButton ? (
                      <Box
                        key={title}
                        ref={categoriesMenuRef}
                        onMouseEnter={(e) => {
                          setIsCategoriesMenuOpen(true);
                        }}
                        onMouseLeave={(e) => {
                          setIsCategoriesMenuOpen(false);
                        }}
                      >
                        <CategoryButton
                          component={Button}
                          title={title}
                          startIcon={<BiMenu />}
                          disableRipple
                          sx={{
                            paddingLeft: 2.5,
                            paddingRight: 2.5,
                            marginLeft: -2.5,
                            backgroundColor: isCategoriesMenuOpen
                              ? 'white'
                              : 'transparent',
                          }}
                        >
                          {title}
                        </CategoryButton>
                        <CategoriesMenu
                          setIsCategoriesMenuOpen={setIsCategoriesMenuOpen}
                          isCategoriesMenuOpen={isCategoriesMenuOpen}
                          anchorEl={navRef.current}
                          categoriesTree={categoriesTree}
                        />
                      </Box>
                    ) : (
                      <CategoryButton
                        key={title}
                        component={NavLink}
                        to={url}
                        title={title}
                        startIcon={contactsButton && <FiPhone />}
                        sx={{
                          paddingLeft: 2.5,
                          paddingRight: 2.5,
                          marginLeft: contactsButton ? 'auto' : 0,
                          '&:last-child': {
                            marginRight: -2.5,
                          },
                        }}
                      >
                        {title}
                      </CategoryButton>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;
