import { Box, Typography } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledOrderSummaryBox, StyledOrderSummaryText } from '../../../styles';

// function calculateSavedPrice(price, discount) {
//   return price * (discount / 100);
// }

const OrderSummaryCard = ({
  cart: cartObject,
  total,
  cartTaxes,
  deliveryCost,
}) => {
  const { cart, total: subTotal, discount } = cartObject;
  const totalUnits = cart.reduce(
    (previousValue, item) => previousValue + item.quantity,
    0
  );
  const totalReferences = cart.length;
  const orderSummary = [
    {
      text: 'Total de Unidades',
      value: totalUnits,
    },
    {
      text: 'Referências',
      value: totalReferences,
    },
    {
      text: 'Subtotal',
      value: formatCurrency(subTotal),
    },
    { text: 'IVA (23%)', value: formatCurrency(cartTaxes) },
    {
      text: 'Envio',
      value: formatCurrency(deliveryCost),
    },
    {
      text: 'Descontos',
      value: formatCurrency(discount),
    },
  ];
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { sm: '60%', md: '30%' },
        height: '100%',
        position: { md: 'sticky' },
        top: { md: `${headerHeight + 20}px` },
        marginBottom: '30px',
      }}
    >
      <Box
        sx={{
          padding: '20px 15px',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          borderRadius: '4px',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            marginBottom: '30px',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          Resumo
        </Typography>

        {orderSummary.map(({ text, value }) => (
          <StyledOrderSummaryBox
            key={text}
            color={text === 'Descontos' && 'danger.main'}
          >
            <StyledOrderSummaryText>{text}</StyledOrderSummaryText>
            <StyledOrderSummaryText>{value}</StyledOrderSummaryText>
          </StyledOrderSummaryBox>
        ))}

        <StyledOrderSummaryBox
          sx={{ marginTop: '30px', marginBottom: 0, alignItems: 'baseline' }}
        >
          <StyledOrderSummaryText>Total</StyledOrderSummaryText>

          <Typography
            fontSize='1.4em'
            fontWeight={700}
          >
            {formatCurrency(total)}
          </Typography>
        </StyledOrderSummaryBox>
      </Box>
      <Typography
        sx={{
          fontSize: '0.8rem',
          textAlign: 'right',
          marginTop: 2,
        }}
      >
        Portes gratuitos em encomendas acima de 300€
      </Typography>
    </Box>
  );
};

export default OrderSummaryCard;
