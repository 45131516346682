import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { COMPANY_INFO } from '../../variables';

const AboutUs = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h5'
        gutterBottom
        sx={{ fontSize: '1.1em' }}
      >
        Sobre a {COMPANY_INFO.name}
      </Typography>

      <Typography
        marginBottom={1}
        component={RouterLink}
        to='/page/quem-somos'
        title='Quem Somos'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Quem Somos
      </Typography>

      <Typography
        marginBottom={1}
        component={RouterLink}
        to='/page/contactos'
        title='Contactos'
        sx={{
          display: 'block',
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        Contactos
      </Typography>
    </Grid>
  );
};

export default AboutUs;
