import { Box, Typography } from '@mui/material';
import { IconContext } from 'react-icons';
import { BsCheckCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { StyledTextButton } from '../../styles';
import { REGISTER_SUCCESS_TEXT } from '../../variables';

const RegisterSuccess = () => {
  return (
    <Box
      sx={{
        width: { xs: '80vw', sm: '30vw' },
        marginY: { xs: '20px', sm: '60px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '20px',
      }}
    >
      <IconContext.Provider value={{ style: { color: '#4FD290', margin: '10px' } }}>
        <BsCheckCircle size={64} />
      </IconContext.Provider>

      <Typography gutterBottom>{REGISTER_SUCCESS_TEXT}</Typography>

      <StyledTextButton
        variant='contained'
        component={Link}
        to='/'
        title='Ir para a homepage'
      >
        Ir para a homepage
      </StyledTextButton>
    </Box>
  );
};

export default RegisterSuccess;
