import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GrDocumentPdf } from 'react-icons/gr';
import { formatCurrency } from '../../../constants/utils';
import generatePDF from '../../../helpers/generatePDF';
import { StyledTableCell } from '../../../styles';

const OrdersTable = ({ shownOrders }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ marginY: '20px' }}
    >
      <Table
        size='small'
        id='orders-table'
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>Número</StyledTableCell>

            <StyledTableCell>Data</StyledTableCell>

            <StyledTableCell>Estado</StyledTableCell>

            <StyledTableCell>Quantidade</StyledTableCell>

            <StyledTableCell>Total</StyledTableCell>

            <StyledTableCell> </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {shownOrders.map((order) => {
            const { reference, date, status, total, items } = order;
            const dateFormatted = new Date(date).toLocaleDateString('pt-PT');

            const quantity = items.reduce(
              (previousValue, { quantity }) => previousValue + quantity,
              0
            );

            return (
              <TableRow
                key={reference}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell>{reference}</StyledTableCell>

                <StyledTableCell sx={{ whiteSpace: 'nowrap' }}>
                  {dateFormatted}
                </StyledTableCell>

                <StyledTableCell>{status}</StyledTableCell>

                <StyledTableCell>{quantity}</StyledTableCell>

                <StyledTableCell>{formatCurrency(total)}</StyledTableCell>

                <StyledTableCell>
                  <IconButton
                    size='small'
                    title='Exportar em PDF'
                    onClick={() => generatePDF(order)}
                  >
                    <GrDocumentPdf />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersTable;
