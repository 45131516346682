import { Box, Button, OutlinedInput, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeProductFromCart, updateCartProducts } from '../../../../api/checkout';
import {
  removeFromCart,
  removeQuantityFromCartItem,
  setCart,
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../../state/cartSlice';
import EliminateFromCartButton from './EliminateFromCartButton';

export const QuantitySelectorInput = styled(OutlinedInput)(() => ({
  fontSize: '0.85rem',
  width: '40px',
  height: '28px',
  '.MuiInputBase-input': {
    textAlign: ' center',
    padding: 0,
  },
}));

const SummaryQuantity = ({ quantity, id, reference, cart, customerId, disableActions }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);
  const [temporaryQuantity, setTemporaryQuantity] = useState(quantity);

  useEffect(() => {
    setTemporaryQuantity(quantity);
  }, [dispatch, quantity]);

  function decrement(reference) {
    if (disableActions) return;

    const productQuantityDecreased = cart.map(({ quantity, ...cartItem }) => ({
      ...cartItem,
      quantity: cartItem.reference === reference ? quantity - 1 : quantity,
    }));

    // When decrementing if the quantity is 1, trigger the delete product from
    // cart action with an alert
    const productToRemove = productQuantityDecreased.find(
      (product) => product.reference === reference
    );
    if (productToRemove.quantity === 0) {
      setOpenFeedback(true);
      return;
    }

    dispatch(removeQuantityFromCartItem(productQuantityDecreased));

    updateCartProducts(productQuantityDecreased, customerId)
      .then(({ total_discount, total: totalPrice }) => {
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  function increment(reference) {
    if (disableActions) return;

    const productQuantityIncreased = cart.map(({ quantity, ...cartItem }) => ({
      ...cartItem,
      quantity: cartItem.reference === reference ? quantity + 1 : quantity,
    }));

    dispatch(updateCart(productQuantityIncreased));

    updateCartProducts(productQuantityIncreased, customerId)
      .then(({ total_discount, total: totalPrice }) => {
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  function handleProductDelete(id, reference) {
    dispatch(removeFromCart({ id, reference }));

    removeProductFromCart(reference, customerId)
      .then(({ cart_items: cart, total: totalPrice, total_discount }) => {
        dispatch(setCart(cart));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  const openFieldChange = (e) => {
    //  IF number of caracteres is greater than 3 caracteres » return
    if (e.length > 3 || disableActions) return;

    const newQuantityNumbered = Number(e);

    setTemporaryQuantity(newQuantityNumbered);

    //  IF number of caracteres is equal 0 » return
    if (e.length === 0) return;

    const productQuantityChanged = cart.map(({ quantity, ...cartItem }) => ({
      ...cartItem,
      quantity: cartItem.reference === reference ? newQuantityNumbered : quantity,
    }));

    dispatch(updateCart(productQuantityChanged));

    updateCartProducts(productQuantityChanged, customerId)
      .then(({ total_discount, total: totalPrice }) => {
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Button
          title='Diminuir quantidade'
          sx={{ minWidth: '30px' }}
          disabled={disableActions}
          onClick={() => decrement(reference)}
        >
          -
        </Button>

        <QuantitySelectorInput
          id='quantity-selector'
          required
          value={temporaryQuantity}
          onChange={(e) =>
            e.target.value !== temporaryQuantity && disableActions
              ? openFieldChange(e.target.value)
              : null
          }
          onBlur={({ target: { value } }) => Number(value) === 0 && setTemporaryQuantity(quantity)}
        />

        <Button
          title='Aumentar quantidade'
          sx={{ minWidth: '30px' }}
          disabled={disableActions}
          onClick={() => increment(reference)}
        >
          +
        </Button>
      </Box>

      {!disableActions && (
        <EliminateFromCartButton
          onDelete={() => handleProductDelete(id, reference)}
          feedback={[openFeedback, setOpenFeedback]}
        />
      )}
    </Box>
  );
};

export default SummaryQuantity;
