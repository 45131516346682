import { Box } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { StyledTextfield } from '../../../styles';

const CompanyInfo = ({
  data: { name, street, zipCode, city, country, taxNumber },
}) => {
  const [XSMALL_DEVICES] = useOutletContext();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: 2,
        }}
      >
        <StyledTextfield
          label='Nome'
          defaultValue={name}
          disabled
          sx={{
            flex: 1,
          }}
        />

        <StyledTextfield
          label='Morada'
          defaultValue={street}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: 2,
        }}
      >
        <StyledTextfield
          label='Código Postal'
          defaultValue={zipCode}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='Localidade'
          defaultValue={city}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='País'
          defaultValue={country}
          disabled
          sx={{ flex: 1 }}
        />

        <StyledTextfield
          label='NIF'
          defaultValue={taxNumber}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>
    </>
  );
};

export default CompanyInfo;
