import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './ExportToExcelButton.css';

const ExportToExcelButton = ({ isOrders }) => {
  return (
    <ReactHTMLTableToExcel
      id='export-to-excel-button'
      className='export-to-excel-button'
      table={isOrders ? 'orders-table' : 'receipts-table'}
      filename={isOrders ? 'tabela-encomendas' : 'tabela-recibos'}
      sheet={isOrders ? 'tabela-encomendas' : 'tabela-recibos'}
      buttonText={'Exportar para .xls'}
    />
  );
};

export default ExportToExcelButton;
